'use client';

import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useResizeViewport } from '$util/hooks/useResizeViewport';
import { VrmLookupModal } from '../VrmLookupModal';
import type { CmsFixieHeroSectionClientProps } from './schema';

import styles from './index.module.scss';

export default function CmsFixieHeroSectionClient(props: CmsFixieHeroSectionClientProps): ReactNode {
  const { id, heading, ctaText, formProps, campaign } = props;
  const [showStickyButton, setShowStickyButton] = useState(false);

  const [stickyButtonIntersectionObserver, setStickyButtonIntersectionObserver] = useState<
    IntersectionObserver | undefined
  >(undefined);

  /**
   * @NOTE implementation currently fires the intersection observer on intersection
   * for all viewports because
   *
   * 1. we have not yet moved the useResizeViewport hook
   * to the new grid breakpoints
   * 2. This needs to be run in the useEffect hook so not sure of the best way to
   * do it in both hooks at the moment
   *
   * This means the IntersectionObserver callback will be run for every breakpoint when
   * the intersection ratio threshold is crossed - this is still much better performance
   * than using a scroll listener and with greatly reduce jank already.
   *
   * @TODO extract the Intersection Observers callback function outside the component scope
   *
   * @TODO when the useResizeViewport component is using the new breakpoints,
   * then refactor so that the observer is initialised independtly, that the target is observed
   * when the viewport is matched and unobserved when it does not.
   */
  useEffect(() => {
    const headingElement = document.querySelector<HTMLElement>('.FixieHeading');
    if (campaign || !headingElement) return undefined;
    const contentSectionEl = document.querySelector<HTMLElement>(`.FixieHeroSection`);
    const topNavBarEl = document.querySelector<HTMLElement>('.TopNavigationBar');

    if (!contentSectionEl || !topNavBarEl) return undefined;
    const isTop =
      contentSectionEl.parentElement?.parentElement?.previousElementSibling?.firstChild ===
      topNavBarEl.parentElement;
    if (!isTop) {
      contentSectionEl.firstElementChild?.classList.remove('top');
      return undefined;
    }

    topNavBarEl.classList.add('transparent');
    topNavBarEl.classList.add('transition');

    const observerTarget = headingElement;
    const toggleNavBg = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (window.innerWidth >= 600) {
          if (entry.intersectionRatio < 1) {
            // make nav opaque
            topNavBarEl.classList.remove('transparent');
          } else {
            // make nav transparent
            topNavBarEl.classList.add('transparent');
          }
        }
      });
    };
    const observer = new IntersectionObserver(toggleNavBg, {
      rootMargin: '-80px 0px 0px',
      threshold: [1],
    });

    observer.observe(observerTarget);

    return () => {
      observer.unobserve(observerTarget);
    };
  }, [campaign]);

  useEffect(() => {
    const heroVrmElement = document.querySelector<HTMLElement>('.FixieVrmForm');
    if (heroVrmElement) {
      const target = heroVrmElement;
      const observer = new IntersectionObserver(
        ([observedTarget]) => {
          const { isIntersecting } = observedTarget;
          if (window.scrollY === 0) {
            setShowStickyButton(false);
          } else {
            setShowStickyButton(!isIntersecting);
          }
        },
        {
          threshold: [0],
        }
      );

      observer.observe(target);

      setStickyButtonIntersectionObserver(observer);

      return () => {
        observer.unobserve(target);
      };
    }
    return () => undefined; // needed due to consistent return lint rule
  }, [setStickyButtonIntersectionObserver, setShowStickyButton]);

  useResizeViewport({
    breakpoint: 'small',
    direction: 'up',
    fn: (isDesktop) => {
      const heroVrmElement = document.querySelector<HTMLElement>('.FixieVrmForm');
      // @NOTE if desktop, don't show
      if (heroVrmElement) {
        if (isDesktop) {
          stickyButtonIntersectionObserver?.unobserve(heroVrmElement);
          setShowStickyButton(false);
        } else {
          stickyButtonIntersectionObserver?.observe(heroVrmElement);
        }
      }
    },
  });
  return (
    showStickyButton && (
      <div className={styles.stickyCTA}>
        <VrmLookupModal
          id={id}
          heading={heading}
          ctaText={ctaText}
          toggleButton={{ children: ctaText, size: 'M' }}
          formProps={formProps}
        />
      </div>
    )
  );
}
