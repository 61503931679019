import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import { Card } from '@/components/atoms/Card';
import { HeadingLevels } from '@/components/atoms/HeadingLevels';

import styles from './index.module.scss';

export type ServiceCardProps = ComponentPropsWithoutRef<'article'> & {
  readonly heading: ReactNode;
  readonly label?: string;
  readonly labelColor?: string;
};

/**
 * @design https://www.figma.com/file/gcOEaiZZFBdrITqksuwsnx/Landing-pages?type=design&node-id=1271-62632&mode=design&t=vJDRIzgS7b09c9Nm-0
 */
export function ServiceCard({
  heading,
  label,
  labelColor,
  className,
  children,
  ...props
}: ServiceCardProps): JSX.Element {
  return (
    <article className={classNames(ServiceCard.displayName, styles.component, className)} {...props}>
      <div className={styles.label}>
        {label && (
          <div className={styles.labelText} style={{ background: labelColor }}>
            {label}
          </div>
        )}
        <div className={styles.labelBorder} style={{ background: labelColor }} />
      </div>
      <Card className={styles.Card} elevation={3} cornerRadius={3} style={{ borderColor: labelColor }}>
        <HeadingLevels semanticLevel={3} styleLevel={5}>
          {heading}
        </HeadingLevels>
        {children}
      </Card>
    </article>
  );
}
ServiceCard.displayName = 'ServiceCard';
