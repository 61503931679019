/**
 * @note index starting from 1
 */
export const getCurrentCarouselItemIndex = (
  carouselViewport: HTMLDivElement,
  carouselItemRelevantWidth: number,
  ribbonWidth: number,
  desktopOffset: number
): number => {
  if (Math.round(carouselViewport.scrollLeft + carouselViewport.clientWidth) >= ribbonWidth) {
    const length = Array.from(carouselViewport.children).at(0)?.children?.length ?? 0;
    return Math.max(1, length);
  }

  const itemIndex = Math.ceil(
    parseFloat(((carouselViewport.scrollLeft - desktopOffset) / carouselItemRelevantWidth).toFixed(1))
  );

  return itemIndex + 1;
};
