import type { Segment } from '@fixter/i18n';
import type { Dimensions } from './dimensions';
import type { ProductFieldObject } from './enhanced-ecommerce';
// eslint-disable-next-line import/no-cycle
import type { CheckoutStep } from './helpers';

export type SuccessPageDisplayedEvent = {
  readonly bookingId: number;
  readonly total: string;
  readonly vat: string;
  readonly promotionCode?: string;
  readonly basket?: SuccessPageDisplayedEventBasket[];
  readonly items: ProductItem[];
  readonly segment: Segment;
};

export type SuccessPageDisplayedEventBasket = ProductFieldObject;

export interface VehicleAnalytics {
  [Dimensions.DIMENSION_MAKE]?: string;
  [Dimensions.DIMENSION_FUEL_TYPE]?: number;
  [Dimensions.METRIC_VEHICLE_AGE]?: number;
  [Dimensions.METRIC_ENGINE_SIZE]?: string;
  [Dimensions.METRIC_DAYS_TO_MOT]?: number;
  [Dimensions.METRIC_LAST_KNOWN_MILEAGE]?: number;
}

export interface BaseAnalytics {
  id?: string;
  name?: string;
}

export interface ProductAnalytics extends BaseAnalytics {
  category?: string;
  event_category?: string;
  variant?: string;
  price?: string;
  [Dimensions.METRIC_MARKET_PRICE]?: string;
}

export interface ProductsListAnalytics extends VehicleAnalytics, ProductAnalytics {
  list?: string;
  position?: number;
}

export interface ProductsAddedToBasketAnalytics extends VehicleAnalytics, ProductAnalytics {
  quantity: number;
  position: number;
  currency: string;
  value: number;
  items: ProductItem[];
  component: string;
  [Dimensions.DIMENSION_POSTCODE_LANDING]: string;
}

export interface ProductItem {
  item_id: string;
  item_name: string;
  price: number;
}

export interface PurchaseParameters {
  id: string;
  affiliation: string;
  step: CheckoutStep;
  revenue: string;
  currency: string;
  transaction_id?: string;
  value: number;
  coupon?: string;
  shipping?: number;
  tax?: number;
  items: ProductItem[];
}

export interface PromotionParameters {
  promotion_id: string;
  promotion_name: string;
  id: string;
  name: string;
}

/**
 * @deprecated use App Router ga4Analytics events
 */
export enum AnalyticsEvents {
  VEHICLE_LOOKUP_SUCCESS = 'VEHICLE_LOOKUP_SUCCESS',
  VEHICLE_LOOKUP_ERROR = 'VEHICLE_LOOKUP_ERROR',

  PRODUCT_DISPLAYED = 'PRODUCT_DISPLAYED',
  MOT_SERVICES_TAB_DISPLAYED = 'MOT_SERVICES_TAB_DISPLAYED',
  SERVICES_TAB_DISPLAYED = 'SERVICES_TAB_DISPLAYED',
  DIAGNOSTICS_TAB_DISPLAYED = 'DIAGNOSTICS_TAB_DISPLAYED',
  REPAIRS_TAB_DISPLAYED = 'REPAIRS_TAB_DISPLAYED',

  VALUE_PROPOSITION_VIDEO_CLICKED = 'VALUE_PROPOSITION_VIDEO_CLICKED',

  /**
   * @deprecated use `quoteModal().trackModalOpen(VRM_LOOKUP_MODAL_OPENED)`
   */
  GET_INSTANT_QUOTE_MODAL_CLICKED = 'GET_INSTANT_QUOTE_MODAL_CLICKED',

  ADD_TO_BASKET = 'ADD_TO_BASKET',
  REMOVE_FROM_BASKET = 'REMOVE_FROM_BASKET',

  ADD_COUPON = 'ADD_COUPON',

  MOT_REMINDER_MODAL_DISPLAYED = 'MOT_REMINDER_MODAL_DISPLAYED',
  MOT_REMINDER_SUCCESS = 'MOT_REMINDER_SUCCESS',
  MOT_REMINDER_ERROR = 'MOT_REMINDER_ERROR',

  CALLBACK_SUCCESS = 'CALLBACK_SUCCESS',

  EVENT_ECOMMERCE = 'ECommerce',
  EVENT_VEHICLE = 'Vehicle',

  OFFLINE_QUOTE_REQUEST_DISPLAYED = 'OFFLINE_QUOTE_REQUEST_DISPLAYED',
  PRICE_RANGE_DISPLAYED = 'PRICE_RANGE_DISPLAYED',

  VIDEO_CONSULTATION_CLICKED = 'VIRTUAL_CONSULTATION',

  SAVE_QUOTE_BANNER_DISPLAYED = 'SAVE_QUOTE_BANNER_DISPLAYED',
  SAVE_QUOTE_BANNER_DISMISSED = 'SAVE_QUOTE_BANNER_DISMISSED',
  SAVE_QUOTE_BANNER_SUBMITTED = 'SAVE_QUOTE_BANNER_SUBMITTED',

  DOWNLOAD_SERVICE_SHEET_CLICKED = 'DOWNLOAD_SERVICE_SHEET_CLICKED',

  QUOTES_PAGE_DISPLAYED = 'QUOTES_PAGE_DISPLAYED',
  SCHEDULE_PAGE_DISPLAYED = 'SCHEDULE_PAGE_DISPLAYED',
  PERSONAL_DETAILS_PAGE_DISPLAYED = 'PERSONAL_DETAILS_PAGE_DISPLAYED',
  PAYMENT_PAGE_DISPLAYED = 'PAYMENT_PAGE_DISPLAYED',
  SUCCESS_PAGE_DISPLAYED = 'SUCCESS_PAGE_DISPLAYED',

  COLLECTION_TIME_DISPLAYED = 'COLLECTION_TIME_DISPLAYED',

  ADD_TO_CALENDAR_CLICKED = 'ADD_TO_CALENDAR_CLICKED',

  BUNDLE_PAGE_DISPLAYED = 'BUNDLE_PAGE_DISPLAYED',
  BUNDLE_PAGE_ADDED = 'BUNDLE_PAGE_ADDED',
  BUNDLE_PAGE_SKIPPED = 'BUNDLE_PAGE_SKIPPED',

  ADDRESS_SEARCH_TERM = 'ADDRESS_SEARCH_TERM',

  OUT_OF_COVERAGE = 'OUT_OF_COVERAGE',
  SALES_MODAL = 'SALES_MODAL',
  SALES_MODAL_CTA = 'SALES_MODAL_CTA',

  FIXTER_WARRANTY_QUOTES_EXPLAINER_CLICKED = 'FIXTER_WARRANTY_QUOTES_EXPLAINER_CLICKED',
  PARTS_QUALITY_QUOTES_EXPLAINER_CLICKED = 'PARTS_QUALITY_QUOTES_EXPLAINER_CLICKED',
  EXTRA_WORK_QUOTES_EXPLAINER_CLICKED = 'EXTRA_WORK_QUOTES_EXPLAINER_CLICKED',
  CnD_EXPLAINER_SCHEDULE_CLICKED = 'CnD_EXPLAINER_SCHEDULE_CLICKED',
  INSURANCE_EXPLAINER_SCHEDULE_CLICKED = 'INSURANCE_EXPLAINER_SCHEDULE_CLICKED',
  DRIVER_EXPLAINER_SCHEDULE_CLICKED = 'DRIVER_EXPLAINER_SCHEDULE_CLICKED',
  GARAGE_EXPLAINER_SCHEDULE_CLICKED = 'GARAGE_EXPLAINER_SCHEDULE_CLICKED',
  BOOK_NOW_PAY_LATER_CLICKED = 'BOOK_NOW_PAY_LATER_CLICKED',

  TRACK_BOOKING_COMPONENT_DISPLAYED = 'TRACK_BOOKING_COMPONENT_DISPLAYED',
  TRACK_MY_BOOKING_CLICKED = 'TRACK_MY_BOOKING_CLICKED',
  TRACK_HELP_CARD_CONTACT_US_CLICKED = 'TRACK_HELP_CARD_CONTACT_US_CLICKED',
  TRACK_HELP_CARD_FAQ_CLICKED = 'TRACK_HELP_CARD_FAQ_CLICKED',

  FOOTER_FAQ_CLICKED = 'FOOTER_FAQ_CLICKED',
  SEE_MAP_CLICKED = 'SEE_MAP_CLICKED',

  PRICE_MATCH_PROMISE_CLICKED = 'PRICE_MATCH_PROMISE_CLICKED',
  MONEY_BACK_GUARANTEE_CLICKED = 'MONEY_BACK_GUARANTEE_CLICKED',

  CHECKOUT_FAQ_QUESTION_CLICKED = 'CHECKOUT_FAQ_QUESTION_CLICKED',
  OPTIONAL_CnD_CUSTOMER_CLICKED = 'OPTIONAL_CnD_CUSTOMER_CLICKED',

  OPTIONAL_CnD_NO_ELIGIBLE_GARAGES = 'OPTIONAL_CnD_NO_ELIGIBLE_GARAGES',
  CnD_3_ELIGIBLE_GARAGES = 'CnD_3_ELIGIBLE_GARAGES',
  CnD_2_ELIGIBLE_GARAGES = 'CnD_2_ELIGIBLE_GARAGES',
  CnD_1_ELIGIBLE_GARAGES = 'CnD_1_ELIGIBLE_GARAGES',
  CnD_3_TOTAL_GARAGES = 'CnD_3_TOTAL_GARAGES',
  CnD_2_TOTAL_GARAGES = 'CnD_2_TOTAL_GARAGES',
  CnD_1_TOTAL_GARAGES = 'CnD_1_TOTAL_GARAGES',
  OPTIONAL_CnD_AVERAGE_DISTANCE_ELIGIBLE_GARAGES = 'OPTIONAL_CnD_AVERAGE_DISTANCE_ELIGIBLE_GARAGES',
  OPTIONAL_CnD_CLOSEST_DISTANCE_ELIGIBLE_GARAGES = 'OPTIONAL_CnD_CLOSEST_DISTANCE_ELIGIBLE_GARAGES',
  GOOGLE_REVIEWS_SUMMARY_CLICKED = 'GOOGLE_REVIEWS_SUMMARY_CLICKED',
  EWO_BOOKING_ID = 'EWO_BOOKING_ID',
  EWO_PROPOSED_DANGEROUS_INFO_EXPANDED = 'EWO_PROPOSED_DANGEROUS_INFO_EXPANDED',
  EWO_PROPOSED_MAJOR_INFO_EXPANDED = 'EWO_PROPOSED_MAJOR_INFO_EXPANDED',
  EWO_PROPOSED_MINOR_INFO_EXPANDED = 'EWO_PROPOSED_MINOR_INFO_EXPANDED',
  EWO_PROPOSED_ADVISORY_INFO_EXPANDED = 'EWO_PROPOSED_ADVISORY_INFO_EXPANDED',
  EWO_PROPOSED_HERE_TO_HELP_CLICKED = 'EWO_PROPOSED_HERE_TO_HELP_CLICKED',
  EWO_PAYMENT_HERE_TO_HELP_CLICKED = 'EWO_PAYMENT_HERE_TO_HELP_CLICKED',
  EWO_SUCCESS_HERE_TO_HELP_CLICKED = 'EWO_SUCCESS_HERE_TO_HELP_CLICKED',
  EWO_PROPOSED_IMAGE_CLICKED = 'EWO_PROPOSED_IMAGE_CLICKED',
  EWO_PROPOSED_DANGEROUS_WO_CALL_US_CLICKED = 'EWO_PROPOSED_DANGEROUS_WO_CALL_US_CLICKED',
  EWO_PROPOSED_MAJOR_WO_CALL_US_CLICKED = 'EWO_PROPOSED_MAJOR_WO_CALL_US_CLICKED',
  EWO_PROPOSED_DANGEROUS_ACCEPTED = 'EWO_PROPOSED_DANGEROUS_ACCEPTED',
  EWO_PROPOSED_MAJOR_ACCEPTED = 'EWO_PROPOSED_MAJOR_ACCEPTED',
  EWO_PROPOSED_MINOR_ACCEPTED = 'EWO_PROPOSED_MINOR_ACCEPTED',
  EWO_PROPOSED_ADVISORY_ACCEPTED = 'EWO_PROPOSED_ADVISORY_ACCEPTED',
  EWO_PROPOSED_DANGEROUS_DECLINED = 'EWO_PROPOSED_DANGEROUS_DECLINED',
  EWO_PROPOSED_MAJOR_DECLINED = 'EWO_PROPOSED_MAJOR_DECLINED',
  EWO_PROPOSED_MINOR_DECLINED = 'EWO_PROPOSED_MINOR_DECLINED',
  EWO_PROPOSED_ADVISORY_DECLINED = 'EWO_PROPOSED_ADVISORY_DECLINED',
  EWO_PROPOSED_MENU_CLICKED = 'EWO_PROPOSED_MENU_CLICKED',
  EWO_PROPOSED_CALL_US_CLICKED = 'EWO_PROPOSED_CALL_US_CLICKED',
  EWO_PROPOSED_PAYMENT_CALL_US_CLICKED = 'EWO_PROPOSED_PAYMENT_CALL_US_CLICKED',
  EWO_PROPOSED_SUCCESS_CALL_US_CLICKED = 'EWO_PROPOSED_SUCCESS_CALL_US_CLICKED',
  EWO_PROPOSED_CONFIRM_BUTTON_CLICKED = 'EWO_PROPOSED_CONFIRM_BUTTON_CLICKED',
  EWO_PAYMENT_CONFIRM_BUTTON_CLICKED = 'EWO_PAYMENT_CONFIRM_BUTTON_CLICKED',
  EWO_PAYMENT_WARRANTY_EXPANDED = 'EWO_PAYMENT_WARRANTY_EXPANDED',
  NEED_HELP_BUTTON_CLICKED = 'NEED_HELP_BUTTON_CLICKED',
  NEED_HELP_MODAL_CALL_US_CLICKED = 'NEED_HELP_MODAL_CALL_US_CLICKED',
  NEED_HELP_MODAL_LIVE_CHAT_CLICKED = 'NEED_HELP_MODAL_LIVE_CHAT_CLICKED',
  NEED_HELP_MODAL_EMAIL_US_CLICKED = 'NEED_HELP_MODAL_EMAIL_US_CLICKED',
  NEED_HELP_MODAL_CONTACT_FORM_CLICKED = 'NEED_HELP_MODAL_CONTACT_FORM_CLICKED',
  NEED_HELP_MODAL_FAQ_CLICKED = 'NEED_HELP_MODAL_FAQ_CLICKED',
  EDIT_BOOKING_BUTTON_CLICKED = 'EDIT_BOOKING_BUTTON_CLICKED',
  DOWNLOAD_INVOICE_BUTTON_CLICKED = 'DOWNLOAD_INVOICE_BUTTON_CLICKED',
  DOWNLOAD_SERVICE_SHEET_BUTTON_CLICKED = 'DOWNLOAD_SERVICE_SHEET_BUTTON_CLICKED',
  VIEW_MOT_CERTIFICATE_BUTTON_CLICKED = 'VIEW_MOT_CERTIFICATE_BUTTON_CLICKED',

  UPSELL_CLICKED_ON_WHATS_INCLUDED = 'UPSELL_CLICKED_ON_WHATS_INCLUDED',
  FIXTER_GUARANTEES_CLICKED = 'FIXTER_GUARANTEES_CLICKED',
  FULL_DESCRIPTION_CLICKED = 'FULL_DESCRIPTION_CLICKED',
  CHECK_LIST_CLICKED = 'CHECK_LIST_CLICKED',
  OIL_ENGINE_CLICKED = 'OIL_ENGINE_CLICKED',
  NEED_OTHER_SERVICES_CLICKED = 'NEED_OTHER_SERVICES_CLICKED',
  PRODUCT_TAB_CLICKED = 'PRODUCT_TAB_CLICKED',
  HOW_IT_WORKS_CAROUSEL_STEP_ACCESSED = 'HOW_IT_WORKS_CAROUSEL_STEP_ACCESSED',
}
