export const productImagesSourceList = {
  default: '/products/default-optimized_npucqy.png',
  mot: '/products/mot-optimized_wpw40s.png',
  'car-service': '/products/default-optimized_npucqy.png',
  'brake-pads-replacement': '/products/brake-pads-optimized_mbibcz.png',
  'brake-pads-front': '/products/brake-pads-optimized_mbibcz.png',
  'brake-pads-rear': '/products/brake-pads-optimized_mbibcz.png',
  'brake-pads': '/products/brake-pads-optimized_mbibcz.png',
  'brake-discs-pads-front': '/products/brake-discs-pads-optimized_dyni1a.png',
  'brake-discs-pads-rear': '/products/brake-discs-pads-optimized_dyni1a.png',
  'brake-discs-pads-replacement': '/products/brake-discs-pads-optimized_dyni1a.png',
  'brake-discs-pads': '/products/brake-discs-pads-optimized_dyni1a.png',
  'timing-belt-replacement': '/products/timing-belt-replacement-optimized_mkgvjv.png',
  'diagnostic-inspection': '/products/default-optimized_npucqy.png',
  'car-diagnostic': '/products/default-optimized_npucqy.png',
  'car-battery-replacement': '/products/car-battery-replacement-optimized_kqmita.png',
  'air-conditioning-regas': '/products/air-conditioning-regas-optimized_glpeqq.png',
  'front-shock-absorber-replacement': '/products/shock-absorber-optimized_wybieu.png',
  'rear-shock-absorber': '/products/rear-shock-absorber-optimized_hqqugm.png',
  'compressor-replacement': '/products/compressor-replacement-optimized_lc0jeb.png',
  'condenser-replacement': '/products/compressor-replacement-optimized_lc0jeb.png',
  'spark-plugs-replacement': '/products/spark-plugs-replacement-optimized_sxubiz.png',
  'water-pump-replacement': '/products/water-pump-replacement-optimized_kevalz.png',
  'exhaust-silencer-replacement': '/products/exhaust-silencer-replacement-optimized_pscrsu.png',
  'clutch-kit-replacement': '/products/clutch-kit-replacement-optimized_cknj2f.png',
  'alternator-replacement': '/products/alternator-replacement-optimized_d4xara.png',
  'oil-and-oil-filter-change': '/products/default-optimized_npucqy.png',
  'front-wheel-alignment': '/products/front-wheel-alignment-optimized_jve5e7.png',
  'starter-motor-replacement': '/products/starter-motor-replacement-optimized_vsukrw.png',
  'egr-valve-replacement': '/products/egr-valve-replacement-optimized_jlr8mk.png',
  'mot-and-service': '/products/default-optimized_npucqy.png',
  'radiator-replacement': '/products/radiator-replacement-optimized_d5gw51.png',
  'power-steering-fluid-flush': '/products/power-steering-fluid-optimized_onqo65.png',
  'engine-coolant-flush': '/products/engine-coolant-flush-optimized_szdu0y.png',
  'brake-fluid-flush': '/products/brake-fluid-replacement-optimized_a4eknv.png',
  'automatic-transmission-fluid-flush': '/products/automatic-transmission-fluid-flush-optimized_kp6imt.png',
  'wing-mirror-replacement': '/products/wing-mirror-replacement-optimized_p53ntm.png',
  'wing-mirror-glass-replacement': '/products/wing-mirror-glass-replacement-optimized_byq4oy.png',
  'thermostat-replacement': '/products/thermostat-replacement-optimized_aeqono.png',
  'wheel-bearing-replacement': '/products/wheel-bearing-replacement-optimized_bhh9rg.png',
  'catalytic-converter-replacement': '/products/catalytic-converter-replacement-optimized_ahc5m5.png',
  'coil-spring-replacement': '/products/coil-spring-replacement-optimized_wff5dr.png',
  'driveshaft-replacement': '/products/driveshaft-replacement-optimized_zuumpl.png',
  'fuel-filter-replacement': '/products/fuel-filter-replacement-optimized_dxnrhk.png',
  'fan-belt-replacement': '/products/fan-belt-replacement-optimized_jsbiir.png',
  'alternator-belt-replacement': '/products/alternator-belt-replacement-optimized_jtnqfu.png',
  'fuel-pump-replacement': '/products/fuel-pump-replacement-optimized_s3qgiq.png',
  'brake-caliper-replacement': '/products/brake-caliper-replacement-optimized_bs3iup.png',
  'track-rod-end-replacement': '/products/track-rod-end-replacement-optimized_gxugfb.png',
  'air-filter-replacement': '/products/air-filter-replacement-optimized_rpqzwp.png',
  'oil-pump-replacement': '/products/oil-pump-replacement-optimized_fn983a.png',
  'crankshaft-pulley-replacement': '/products/crankshaft-pulley-replacement-optimized_spgvum.png',
  'throttle-body-replacement': '/products/throttle-body-replacement-optimized_fuyrgt.png',
  'front-wiper-motor-replacement': '/products/front-wiper-motor-replacement-optimized_fqbqvh.png',
  'exhaust-manifold-replacement': '/products/exhaust-manifold-replacement-optimized_dl3jey.png',
  'wiper-replacement-front': '/products/wiper-replacement-optimized_tgriid.png',
  'wiper-replacement-front-pair': '/products/wiper-replacement-optimized_tgriid.png',
  'wiper-replacement': '/products/wiper-replacement-optimized_tgriid.png',
  'knock-sensor-replacement': '/products/knock-sensor-replacement-optimized_goalgk.png',
  'trailing-arm-replacement': '/products/trailing-arm-replacement-optimized_giayyv.png',
  'fuel-hose-replacement': '/products/fuel-hose-replacement-optimized_c46xwc.png',
  'exhaust-pipe-replacement': '/products/exhaust-pipe-replacement-optimized_itj5qh.png',
  'lower-ball-joint-replacement': '/products/lower-ball-joint-replacement-optimized_cdg9ad.png',
  'instrument-bulb-replacement': '/products/instrument-bulb-replacement-optimized_vnuzpk.png',
  'brake-pedal-switch-replacement': '/products/brake-pedal-switch-replacement-optimized_ykbkzz.png',
  'auxiliary-drive-belt-replacement': '/products/auxiliary-drive-belt-replacement-optimized_ktzwhm.png',
  'abs-wheel-speed-sensor-replacement': '/products/abs-wheel-speed-sensor-replacement-optimized_ny4ieg.png',
  'intake-manifold-gasket-replacement': '/products/intake-manifold-gasket-replacement-optimized_ssn1fb.png',
  'fluid-check': '/products/fluid-free-check_2x_fv0ixh.png',
  'timing-belt-check': '/products/timing-belt-free-check_2x_i9fxxc.png',
  'exhaust-check': '/products/exhaust-free-check_2x_ciacli.png',
  'suspension-check': '/products/suspension-free-check_2x_h9a4iq.png',
  'battery-check': '/products/battery-free-check_2x_lzak0b.png',
  'brakes-check': '/products/brakes-free-check_2x_nqef4e.png',
  'steering-check': '/products/steering-free-check_2x_d6uhnl.png',
  'tyre-check': '/products/tyre-free-check_2x_vxq58u.png',
  'air-con-check': '/products/air-con-free-check_2x_bj8bty.png',
  'anti-roll-bar-drop-link-replacement': '/products/antiroll-bar-drop-link-replacement-optimized_yq0foh.png',
  'brake-hose-replacement': '/products/brake-hose-replacement-optimized_whnmij.png',
  'brake-light-bulb-replacement': '/products/brake-light-bulb-replacement-optimized_y7kelm.png',
  'fuel-pump-relay-replacement': '/products/fuel-pump-relay-replacement-optimized_b7obmt.png',
  'fog-lamp-front-replacement': '/products/fog-lamp-replacement-optimized_owktyz.png',
  'fog-lamp-rear-replacement': '/products/rear-fog-lamp-replacement-optimized_icqgpd.png',
  'headlight-bulb-replacement': '/products/headlamp-bulb-replacement-optimized_difdyp.png',
  'horn-assembly-replacement': '/products/horn-assembly-replacement-optimized_msigib.png',
  'lambda-sensor-replacement': '/products/lambda-sensor-replacement-optimized_qapecx.png',
  'indicator-bulb-replacement': '/products/indicator-bulb-replacement-optimized_xyq9il.png',
  'wiper-linkage-replacement': '/products/wiper-linkage-replacement-optimized_duffib.png',
  'winter-health-check': '/products/330x220_WinterHealthCheck_2x_rklxis.png',
} as { [key: string]: string };
