'use client';

import classNames from 'classnames';
import { useActiveExperiment } from '$util/abTest';
import { ActiveExperiments } from '$util/abTest/experiments';
import { HeroSection } from '@/components/molecules/HeroSection';
import CmsHeroSectionClient from '@/components/organisms/CmsHeroSection/CmsHeroSectionClient';
import type { USPHeroProps } from '@/components/molecules/USPHero';
import { USPHero } from '@/components/molecules/USPHero';
import type { HeroSectionProps } from './types';

import styles from './index.module.scss';

export interface ABTestedHomepageHeroProps {
  readonly heroSectionProps: HeroSectionProps;
  readonly uspHeroProps: Partial<USPHeroProps>;
  readonly campaignId?: string;
  readonly className?: string;
}

export function ABTestedHomepageHero({
  heroSectionProps,
  uspHeroProps,
  campaignId,
  className,
}: ABTestedHomepageHeroProps): JSX.Element {
  const isABTestAnimatedHomepageHeroUK = useActiveExperiment(ActiveExperiments.AnimatedHomepageHeroUK, 'B');
  const isABTestAnimatedHomepageHeroFR = useActiveExperiment(ActiveExperiments.AnimatedHomepageHeroFR, 'B');
  const isABTestAnimatedHomepageActive = isABTestAnimatedHomepageHeroUK || isABTestAnimatedHomepageHeroFR;

  const { heading, ctaLabel, ctaLink, locale, usp1, usp2, usp3 } = uspHeroProps;
  const uspHeroContentProvided = Boolean(heading && ctaLabel && ctaLink && locale && usp1 && usp2 && usp3);

  if (isABTestAnimatedHomepageActive && uspHeroContentProvided) {
    return <USPHero {...(uspHeroProps as USPHeroProps)} />;
  }
  return (
    <>
      <HeroSection className={classNames(styles.topAdjustment, className, 'top')} {...heroSectionProps} />
      <CmsHeroSectionClient
        locale={heroSectionProps.locale}
        sku={heroSectionProps.sku}
        campaignId={campaignId}
        mainCta={heroSectionProps.mainCta}
      />
    </>
  );
}
