import styled from 'styled-components';
import { vars, pxToRem } from '$util/theme';
import type Props from './props';

export default styled.div<Props>`
  border-radius: ${vars.defaultBorderRadius};
  padding: 20px;
  font-size: ${pxToRem(14)};

  &.card-1 {
    background: ${vars.quantumverse.reskinColors.white};
    box-shadow: ${({ shadow }) => (shadow === 'big' ? vars.cardShadowBig : vars.cardShadow)};
  }

  &.card-2 {
    border: solid 1px ${vars.quantumverse.reskinColors.brandLightGray};
  }
`;
