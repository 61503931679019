import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export interface SectionDividerProps extends ComponentPropsWithoutRef<'div'> {
  /**
   * If enabled the component will render a hr element
   * To be used when we intend to introduce a thematic break between paragraph-level elements
   */
  readonly semantic?: boolean;
}

export function SectionDivider({
  className,
  semantic,
  children,
  ...props
}: SectionDividerProps): JSX.Element {
  return (
    <div
      className={classNames(
        SectionDivider.displayName,
        styles.component,
        semantic ?? styles.logoDivider,
        className
      )}
      {...props}
    >
      {semantic && <hr className={styles.logoDivider} />}
      {children}
    </div>
  );
}
SectionDivider.displayName = 'SectionDivider';
