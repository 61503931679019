import React from 'react';
import classNames from 'classnames';
import { Icon } from '@/components/atoms/Icon';
import styles from './index.module.scss';

interface ArrowButtonProps {
  direction: 'left' | 'right';
  onClick: () => void;
  className?: string;
}

export function ArrowButton({ direction, onClick, className }: ArrowButtonProps): JSX.Element {
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button
      type="button"
      onClick={onClick}
      className={classNames(ArrowButton.displayName, styles.arrowButton, className, {
        [styles.left]: direction === 'left',
        [styles.right]: direction === 'right',
      })}
    >
      <Icon className={styles.icon} id="arrows/arrow-right" width={20} height={20} legacy={false} />
    </button>
  );
}

ArrowButton.displayName = 'ArrowButton';
