'use client';

import type { ComponentPropsWithoutRef } from 'react';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import type { Locale } from '@fixter/i18n';
import type { ProcessedCmsContentfulImage } from '$cms/contentful.types';
import { PreloadImage } from '$util/PreloadImage';
import { ContentComponentSection } from '@/components/atoms/ContentComponentSection';
import { Img } from '@/components/atoms/Img';
import { Row, Col } from '@/components/atoms/Grid';
import { HeadingLevels } from '@/components/atoms/HeadingLevels';
import { Button } from '@/components/atoms/Button';
import { PartOfRenault } from '@/components/molecules/PartOfRenault';
import { TrustPilot } from '@/components/atoms/TrustPilot';
import { stopAntiFlicker } from '@/components/templates/ThirdPartyScripts/Kameleoon/KameleoonAntiFlicker';

import styles from './index.module.scss';

export interface USPHeroProps extends ComponentPropsWithoutRef<'div'> {
  readonly heading: string;
  readonly usp1: string;
  readonly usp2: string;
  readonly usp3: string;
  readonly ctaLabel: string;
  readonly ctaLink: string;
  readonly locale: Locale;
  readonly partOfRenaultText?: string;
  readonly partOfRenaultLogo?: Omit<ProcessedCmsContentfulImage, '__typename'>;
  readonly bgColor?: string;
}

function FilledTickCircled(): JSX.Element {
  return (
    <svg
      className={styles.checkMarks}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="tick">
        <rect id="Rectangle 1920" x="3.07446" y="5.29352" width="17.3561" height="14.7753" fill="white" />
        <path
          id="Vector"
          d="M12 24.12C18.6162 24.12 24 18.7362 24 12.12C24 5.50378 18.6162 0.119995 12 0.119995C5.38378 0.119995 0 5.50378 0 12.12C0 18.7362 5.38378 24.12 12 24.12ZM6.84324 12.0357C7.22919 11.6497 7.85189 11.6497 8.23784 12.0357L10.5438 14.3416L16.4205 8.46486C16.8065 8.07891 17.4292 8.07891 17.8151 8.46486C18.2011 8.85081 18.2011 9.47351 17.8151 9.85945L11.2378 16.4335C11.0465 16.6249 10.7935 16.7222 10.5405 16.7222C10.2876 16.7222 10.0346 16.6249 9.84324 16.4335L6.84 13.4303C6.4573 13.0476 6.4573 12.4216 6.84324 12.0357Z"
          fill="#140054"
        />
      </g>
    </svg>
  );
}

export function USPHero({
  className,
  heading,
  usp1,
  usp2,
  usp3,
  ctaLabel,
  ctaLink,
  locale,
  partOfRenaultText,
  partOfRenaultLogo,
  bgColor,
  ...props
}: USPHeroProps): JSX.Element {
  const [startAnimation, setStartAnimation] = useState(false);
  useEffect(() => {
    stopAntiFlicker();
    setStartAnimation(true);
  }, []);

  const consolidatedOtherProps = props;

  if (bgColor) {
    consolidatedOtherProps.style = {
      backgroundColor: bgColor,
    };
  }

  return (
    <ContentComponentSection
      className={classNames(
        USPHero.displayName,
        styles.component,
        className,
        startAnimation && styles.animate
      )}
      {...consolidatedOtherProps}
    >
      <div className={styles.curvedShapeContainer} />
      <Row className={styles.row}>
        <Col>
          <HeadingLevels className={styles.heading} semanticLevel={1} styleLevel={1}>
            {heading}
          </HeadingLevels>
          <div className={styles.usps}>
            <ol className={styles.uspList}>
              <li className={styles.usp}>
                <FilledTickCircled />
                {usp1}
              </li>
              <li className={styles.usp}>
                <FilledTickCircled />
                {usp2}
              </li>
              <li className={styles.usp}>
                <FilledTickCircled />
                {usp3}
              </li>
            </ol>
            <PreloadImage provider="cloudinary" src="/People_hzo6pd.png" displayTo="medium" width={150} />
            <PreloadImage provider="cloudinary" src="/Car_mmf2c9.png" displayTo="medium" width={180} />
            <PreloadImage provider="cloudinary" src="/People_hzo6pd.png" displayFrom="medium" width={220} />
            <PreloadImage provider="cloudinary" src="/Car_mmf2c9.png" displayFrom="medium" width={174} />
            <Img
              className={classNames(styles.positionedImage, styles.peopleImage)}
              provider="cloudinary"
              src="/People_hzo6pd.png"
              alt="A Fixter driver picking up a customer's car"
              width={150}
              height={154}
              loading="eager"
              priority
            />
            <Img
              className={classNames(styles.positionedImage, styles.carImage)}
              provider="cloudinary"
              src="/Car_mmf2c9.png"
              alt="A customer's car"
              width={180}
              height={181}
              loading="eager"
              priority
            />
          </div>
          <Button className={styles.cta} size="L" href={ctaLink}>
            {ctaLabel}
          </Button>
          <div className={styles.lowerContainer}>
            <TrustPilot className={styles.trustpilotReviews} locale={locale} width="100%" theme="dark" />
            {partOfRenaultText && partOfRenaultLogo && (
              <PartOfRenault
                className={styles.partOfRenault}
                layout="horizontal"
                preload
                label={partOfRenaultText}
                logo={partOfRenaultLogo}
              />
            )}
          </div>
        </Col>
      </Row>
    </ContentComponentSection>
  );
}
USPHero.displayName = 'USPHero';
