'use client';

import dynamic from 'next/dynamic';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { recordAnalytics } from '$util/analytics';
import { AnalyticsEvents } from '$util/analytics/types';
import type { ButtonProps } from '$src-components/atoms/Button';
import { useModalQueue } from '$util/hooks/useModalQueue';
import { Button } from '@/components/atoms/Button';
import { LoadingIndicator } from '@/components/atoms/LoadingIndicator';
import type { VrmLookupProps } from '@/components/molecules/VrmLookup';

import styles from './index.module.scss';

/**
 * @note Dynamically import to reduces bundle size because it's needed only after user interaction.
 */
const VrmLookupModalContent = dynamic(
  () => import('./VrmLookupModalContent').then((mod) => mod.VrmLookupModalContent),
  {
    loading: () => (
      <div className={styles.loadingContent}>
        <LoadingIndicator />
      </div>
    ),
  }
);

export interface VrmLookupModalProps {
  readonly id: string;
  readonly heading: ReactNode;
  readonly description?: string;
  readonly ctaText: string;
  readonly toggleButton?: ButtonProps;
  readonly fullWidth?: boolean;
  readonly garageId?: string;
  readonly isGarageMembership?: boolean;
  readonly landingUrl?: string;
  readonly formProps: VrmLookupProps;
}

/**
 * @TODO
 * @deprecated use VrmLookupDumbModal instead
 */
export function VrmLookupModal({
  id,
  heading,
  description,
  ctaText,
  toggleButton = {},
  garageId,
  isGarageMembership = false,
  landingUrl,
  formProps,
}: VrmLookupModalProps & ButtonProps): JSX.Element {
  const [, queueModal] = useModalQueue();

  const vrmLookupProps = {
    className: classNames(styles.modalVrmLookupForm, formProps.className),
    buttonText: ctaText,
    extraParams: {
      garageId,
      isGarageMembership,
    },
    ...formProps,
  };

  if (landingUrl) {
    vrmLookupProps.formAction = `${landingUrl}/vehicles/add`;
  }

  const toggleModal: React.MouseEventHandler = () => {
    recordAnalytics(AnalyticsEvents.GET_INSTANT_QUOTE_MODAL_CLICKED);
    queueModal([
      <VrmLookupModalContent
        key="content"
        heading={heading}
        description={description}
        formProps={vrmLookupProps}
      />,
      'modal',
    ]);
  };

  return <Button id={id} onClick={toggleModal} {...toggleButton} />;
}

VrmLookupModal.displayName = 'VrmLookupModal';

export default VrmLookupModal;
