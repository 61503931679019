import type { HTMLAttributes } from 'react';
import React from 'react';
import classNames from 'classnames';
import { Img } from '$src-components/atoms/Img';
import { CLOUDINARY_URL } from '$constants/cloudinary';
import type { MediaQueryBreakpoint } from '$util/theme/breakpoints';
import styles from './index.module.scss';

export interface PartOfRenaultProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * @TODO add preload support
   */
  readonly preload?: boolean;
  readonly fromBreakpoint?: MediaQueryBreakpoint;
  readonly toBreakpoint?: MediaQueryBreakpoint;
  readonly layout?: 'vertical' | 'horizontal';
  readonly label?: string;
  readonly logo?: {
    readonly img: {
      readonly original_secure_url: string;
      readonly width: number;
      readonly height: number;
    };
    readonly alt: string;
    readonly title: string;
  };
}

const defaultLogo = {
  img: {
    original_secure_url: `${CLOUDINARY_URL}/logos/renault_white_ucyjxf.svg`,
    width: 87,
    height: 39,
  },
  alt: 'Backed by Renault',
  title: '',
};

export function PartOfRenault({
  className,
  preload,
  fromBreakpoint,
  toBreakpoint,
  layout = 'vertical',
  label = 'Backed by',
  logo = defaultLogo,
  ...props
}: PartOfRenaultProps): JSX.Element {
  return (
    <div className={classNames(PartOfRenault.displayName, styles.PartOfRenault, className)} {...props}>
      <div className={classNames(styles.container, styles[layout])}>
        <div className={styles.label}>{label}</div>
        {layout === 'horizontal' ? (
          <Img
            provider="cloudinary"
            src={logo.img.original_secure_url}
            alt={logo.alt}
            title={logo.title}
            width={70}
            height={60}
            // loading={preload ? 'eager' : 'lazy'}
          />
        ) : (
          <Img
            provider="cloudinary"
            src={logo.img.original_secure_url}
            alt={logo.alt}
            title={logo.title}
            width={140}
            height={120}
            // loading={preload ? 'eager' : 'lazy'}
          />
        )}
      </div>
    </div>
  );
}
PartOfRenault.displayName = 'PartOfRenault';
