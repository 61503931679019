import type { ElementType } from 'react';
import React from 'react';
import classNames from 'classnames';
import type { GridProps, RowProps, ColProps } from './types';

import styles from './index.module.scss';

export function Grid<C extends ElementType = 'div'>({
  as,
  className,
  children,
  ...props
}: GridProps<C>): JSX.Element {
  const AsComponent = as ?? 'div';
  return (
    <AsComponent className={classNames(Grid.displayName, className)} {...props}>
      {children}
    </AsComponent>
  );
}
Grid.displayName = 'Grid';

export function Row({ className = '', children, ...props }: RowProps): JSX.Element {
  return (
    <div className={classNames(Row.displayName, styles.Row, className)} {...props}>
      {children}
    </div>
  );
}
Row.displayName = 'Row';

export function Col<C extends ElementType = 'div'>({
  as,
  className,
  extraSmall,
  small,
  medium,
  large,
  extraLarge,
  extraSmallStart,
  smallStart,
  mediumStart,
  largeStart,
  extraLargeStart,
  children,
  order,
  ...props
}: ColProps<C>): JSX.Element {
  const compiledClasses = [
    ['extraSmall', extraSmall],
    ['extraSmall-start', extraSmallStart],
    ['small', small],
    ['small-start', smallStart],
    ['medium', medium],
    ['medium-start', mediumStart],
    ['large', large],
    ['large-start', largeStart],
    ['extraLarge', extraLarge],
    ['extraLarge-start', extraLargeStart],
  ]
    .filter(([, value]) => Boolean(value))
    .map(([bp, value]) => styles[`${bp}-${value}`]);

  if (typeof order !== 'undefined') {
    compiledClasses.push(styles[`col-order-${order}`]);
  }

  const AsComponent = as ?? 'div';

  return (
    <AsComponent
      className={classNames(Col.displayName, styles.Col, ...compiledClasses, className)}
      {...props}
    >
      {children}
    </AsComponent>
  );
}
Col.displayName = 'Col';
