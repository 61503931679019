import type { ComponentPropsWithoutRef } from 'react';

import styles from './index.module.scss';

export interface EmailCtaProps extends ComponentPropsWithoutRef<'div'> {
  readonly cta: string;
  readonly mailto: string;
}

export function EmailCta({ cta, mailto }: EmailCtaProps) {
  return (
    <div className={styles.cta}>
      <strong>{cta}</strong>
      <br />
      <span>
        Email us at <a href={`mailto:${mailto}`}>{mailto}</a>
      </span>
    </div>
  );
}
