'use client';

import React, { useRef, useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { ArrowButton } from '$src-components/atoms/ArrowButtons';
import { ScrollIndicator } from '$src-components/atoms/ScrollIndicator';
import type { AnyObject } from '$util/types';
import vars from '$util/theme/vars';
import styles from './index.module.scss';
import { getCurrentCarouselItemIndex } from './helpers';

export interface CarouselProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode[];
  desktopVisibleItems?: number;
  peek?: number;
  mobilePeek?: number;
  mobileOffset?: number;
  desktopOffset?: number;
  desktopItemMaxWidth?: number;
  gap?: number;
  desktopGap?: number;
  mobileSidePadding?: number;
  onNext?: () => void;
  onPrev?: () => void;
  onItemChange?: (index: number) => void;
}

export function Carousel({
  children,
  desktopVisibleItems = 2,
  peek = 3,
  mobilePeek = 24,
  mobileOffset = 0,
  desktopOffset = 0,
  desktopItemMaxWidth,
  gap = 8,
  desktopGap = 16,
  mobileSidePadding,
  onNext,
  onPrev,
  onItemChange,
  style,
  className,
}: CarouselProps): JSX.Element {
  const carouselViewportRef = useRef<HTMLDivElement>(null);
  const [currentItem, setCurrentItem] = useState(1);
  const overrideStyles = {
    '--desktop-visible-items': desktopVisibleItems,
    '--mobile-offset': `${mobileOffset}px`,
    '--desktop-offset': `${desktopOffset}px`,
    '--desktop-item-max-width': desktopItemMaxWidth ? `${desktopItemMaxWidth}px` : 'unset',
    '--gap': `${gap}px`,
    '--desktop-gap': `${desktopGap}px`,
    '--mobile-side-padding': `${mobileSidePadding ?? 20}px`,
  };
  const cssVariables: AnyObject = {
    '--mobile-peek': `${mobilePeek}px`,
    '--peek': `${peek}px`,
  };
  const consolidatedStyles = { ...cssVariables, ...style, ...overrideStyles };

  const carouselItemRelevantWidth = useCallback(() => {
    if (!carouselViewportRef.current) return undefined;

    const target = carouselViewportRef.current;
    const carouselRibbon = Array.from(target.children).at(0);
    const firstCarouselItem = carouselRibbon?.children[0];
    if (!carouselRibbon || !firstCarouselItem) return undefined;
    const carouselViewportLessThanMedium = target.clientWidth < vars.mediaQueryBreakpoints.medium - 80;
    const currentGap = carouselViewportLessThanMedium ? `${gap}px` : `${desktopGap}px`;
    const { width: carouselItemWidth } = window.getComputedStyle(firstCarouselItem);
    const width =
      parseInt(carouselItemWidth, 10) +
      parseInt(currentGap, 10) / (carouselViewportLessThanMedium ? 1 : desktopVisibleItems) +
      (carouselViewportLessThanMedium ? mobilePeek : peek);
    return width;
  }, [desktopGap, desktopVisibleItems, gap, mobilePeek, peek]);

  useEffect(() => {
    if (!carouselViewportRef.current) return undefined;

    const target = carouselViewportRef.current;
    /**
     * @NOTE this is bad for runtime performance
     *
     * scrollend was previously used with feature sensing would have been
     * preferred, but this results in animations and transitions of the slides
     * and indicators occurring after the slide is settled.
     *
     * The degradation is minimal, does not affect CWV but may result in choppy
     * scrolling and animations of the carousel under some circumstances and
     * devices. The degradation was decided to be acceptable for the aesthetics
     * gained.
     * */
    const eventType = 'scroll';

    const handleScrollIndicator = () => {
      const carouselRibbon = Array.from(target.children).at(0);
      const firstCarouselItem = carouselRibbon?.children[0];
      const relevantWidth = carouselItemRelevantWidth();
      if (!carouselRibbon || !firstCarouselItem || !relevantWidth) return;

      const { width: ribbonWidth } = window.getComputedStyle(carouselRibbon);

      const newItemIndex = getCurrentCarouselItemIndex(
        target,
        relevantWidth,
        parseInt(ribbonWidth, 10),
        target.clientWidth < vars.mediaQueryBreakpoints.medium - 80 ? mobileOffset : desktopOffset
      );

      setCurrentItem(newItemIndex);

      onItemChange?.(newItemIndex - 1); // Call the onItemChange callback with 0-based index
    };

    target.addEventListener(eventType, handleScrollIndicator, false);
    return () => target.removeEventListener(eventType, handleScrollIndicator, false);
  }, [
    carouselItemRelevantWidth,
    carouselViewportRef,
    currentItem,
    desktopGap,
    desktopOffset,
    gap,
    mobileOffset,
    mobilePeek,
    onItemChange,
    peek,
  ]);

  const move = useCallback(
    (direction: 'left' | 'right') => {
      const target = carouselViewportRef?.current;
      const carouselItem = target?.children[0]?.children[0];
      const relevantWidth = carouselItemRelevantWidth();
      if (!carouselItem || !relevantWidth) return;

      target.scrollBy(relevantWidth * (direction === 'left' ? -1 : 1), 0);
      if (direction === 'left' && onPrev) {
        onPrev();
      } else if (direction === 'right' && onNext) {
        onNext();
      }
    },
    [carouselItemRelevantWidth, onNext, onPrev]
  );

  const showArrows = children.length > 2;

  return (
    <div
      style={consolidatedStyles}
      className={classNames(Carousel.displayName, styles.carouselContainer, className)}
    >
      <ArrowButton
        className={classNames(
          styles.carouselArrow,
          (!showArrows || currentItem < 2) && styles.carouselArrowOff
        )}
        direction="left"
        onClick={() => move('left')}
      />
      <ArrowButton
        className={classNames(
          styles.carouselArrow,
          (!showArrows || currentItem >= children.length) && styles.carouselArrowOff
        )}
        direction="right"
        onClick={() => move('right')}
      />
      <div ref={carouselViewportRef} className={styles.carouselViewPort}>
        <ul className={styles.carouselRibbon}>
          {children.map((child, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={classNames(styles.carouselItem, index === currentItem - 1 ? 'selected' : undefined)}
            >
              {child}
            </li>
          ))}
        </ul>
      </div>
      <ScrollIndicator
        items={children.length}
        current={currentItem}
        className={classNames(styles.carouselIndicator, children.length < 3 && styles.carouselIndicatorOff)}
      />
    </div>
  );
}
Carousel.displayName = 'Carousel';
