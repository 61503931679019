import React from 'react';
import type { ReactNode } from 'react';
import { Icon } from '@/components/atoms/Icon';
import styles from './index.module.scss';

enum IconPlaceholders {
  CHECKED = '[checked]',
  UNCHECKED = '[unchecked]',
}

function getStringsFromReactNode(node: ReactNode): string[] {
  let results: string[] = [];

  if (typeof node === 'string') {
    results.push(node);
  } else if (Array.isArray(node)) {
    node.forEach((child) => {
      results = results.concat(getStringsFromReactNode(child));
    });
  } else if (React.isValidElement(node)) {
    const childrenArray = React.Children.toArray(node.props.children);
    childrenArray.forEach((child) => {
      results = results.concat(getStringsFromReactNode(child));
    });
  }

  return results;
}

const renderIconByValue = (value: boolean) => {
  const icon = value ? 'general/check-circle' : 'general/x-circle';
  return (
    <Icon
      id={icon}
      legacy={false}
      className={value === true ? styles.checked : styles.unchecked}
      width={24}
      height={24}
    />
  );
};

/**
 * To display a tick/cross with text, we use a rich text comp. in Contentful.
 * It looks for `[checked]` or `[unchecked]` and renders the correct icon and then
 * renders any other strings under it as text.
 */
const isUsingIconPlaceholder = (value: ReactNode): boolean => {
  const strings = getStringsFromReactNode(value);
  if (strings.includes(IconPlaceholders.CHECKED) || strings.includes(IconPlaceholders.UNCHECKED)) return true;
  return false;
};

function removeStringsFromArray(arr: string[], stringsToRemove: string[]): string[] {
  return arr.filter((item: string) => !stringsToRemove.includes(item));
}

const renderIconWithText = (element: ReactNode) => {
  const strings = getStringsFromReactNode(element);
  const checkedPlaceholder = strings.find((string) => string === IconPlaceholders.CHECKED);
  const otherStrings = removeStringsFromArray(strings, [
    IconPlaceholders.CHECKED,
    IconPlaceholders.UNCHECKED,
  ]);
  const isChecked = Boolean(checkedPlaceholder);
  return (
    <>
      {renderIconByValue(isChecked)}
      <p className={styles.AdditionalText}>{otherStrings}</p>
    </>
  );
};

export const renderColumnValue = (cmsValue: ReactNode | number): ReactNode => {
  if (isUsingIconPlaceholder(cmsValue)) return renderIconWithText(cmsValue);
  if (typeof cmsValue !== 'boolean') return cmsValue;
  return renderIconByValue(cmsValue);
};
