import { useEffect } from 'react';
import { ga4Event } from '@/util/ga4Analytics';

/**
 * In order to make sure we only run the useEffect once, make sure you pass
 * a function whose reference does not change. Either make sure the function
 * is outside of component render or use the useCallback hook.
 */
export const useGA4Tracking = (trackingCall: () => void): void => {
  useEffect(() => {
    if (ga4Event.ready) {
      trackingCall();
    } else {
      document.body.addEventListener('consent-set', trackingCall);

      return () => {
        document.body.removeEventListener('consent-set', trackingCall);
      };
    }

    return undefined;
  }, [trackingCall]);
};
