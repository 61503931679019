/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Component, ReactNode } from 'react';
import React from 'react';
import { get, isFunction, merge } from 'lodash';

export const getComponentName = (component: Component | ReactNode) =>
  get(component, 'displayName', isFunction(component) ? component.name : '');

/**
 * Provide a default theme inside styled css functions
 * @param {Object} defaultTheme
 */
export const styledCssWithTheme =
  (defaultTheme: any) =>
  (cssFunc: any) =>
  ({ theme, ...props }: any) =>
    isFunction(cssFunc) ? cssFunc({ theme: merge(defaultTheme, theme), ...props }) : cssFunc;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OverrideProp<T> = T | { render: ReactNode; [key: string]: any };

/**
 * Helper for overriding default props in a reusable component
 * @deprecated replace with InjectComponent mechanism
 */
export const override = (defaultProp: OverrideProp<any>, defaultRender: () => ReactNode): ReactNode => {
  const render = get(defaultProp, 'render');
  if (typeof render === 'undefined') return defaultRender();
  return render;
};

/**
 * Converts react node into string recursiverly
 *
 * @param {React.ReactNode} reactNode
 * @returns string
 */
export const reactNodeToString = function (reactNode: ReactNode): string {
  let string = '';
  if (typeof reactNode === 'string') {
    string = reactNode.replace(/(<([^>]+)>)/gi, '');
  } else if (typeof reactNode === 'number') {
    string = reactNode.toString();
  } else if (reactNode instanceof Array) {
    reactNode.forEach((child) => {
      string += reactNodeToString(child);
    });
  } else if (React.isValidElement(reactNode)) {
    const childNode = get(
      reactNode,
      'props.dangerouslySetInnerHTML.__html',
      get(reactNode, 'props.children')
    );
    if (childNode) string += reactNodeToString(childNode);
  }
  return string;
};
