'use client';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import React, { useCallback } from 'react';
import type { Segment } from '@fixter/i18n';
import { useModalQueue } from '$util/hooks/useModalQueue';
import { Icon } from '@/components/atoms/Icon';
import { Card } from '@/components/atoms/Card';

import type {
  ComparisonTableRow,
  ComparisonTableService,
  ComparisonTableTranslations,
} from '@/components/molecules/ComparisonTable';
import { ComparisonTable } from '@/components/molecules/ComparisonTable';
import styles from './index.module.scss';

export interface CompareOffersModalTranslations extends ComparisonTableTranslations {
  readonly compareOffersText: string;
}

export interface CompareOffersModalProps extends ComponentPropsWithoutRef<'div'> {
  readonly translations: CompareOffersModalTranslations;
  readonly services: ComparisonTableService[];
  readonly lineItems: ComparisonTableRow[];
  readonly segment: Segment;
}

export function CompareOffersModal({
  translations,
  services,
  lineItems,
  segment,
}: CompareOffersModalProps): ReactNode {
  const { compareOffersText } = translations;
  const [, queueModal] = useModalQueue();

  const onCompareClicked = useCallback(() => {
    queueModal(
      <div className={styles.modalContent}>
        <h5 className={styles.modalHeading}>{compareOffersText}</h5>
        <ComparisonTable
          translations={translations}
          services={services}
          lineItems={lineItems}
          segment={segment}
          forceMobile
        />
      </div>
    );
  }, [compareOffersText, lineItems, queueModal, segment, services]);
  return (
    <Card elevation={1} cornerRadius={3} className={styles.compareServices} onClick={onCompareClicked}>
      <div className={styles.compareIcon}>
        <Icon id="arrows/switch-horizontal-02" legacy={false} width={20} height={20} />
      </div>
      {compareOffersText}
    </Card>
  );
}
CompareOffersModal.displayName = 'CompareOffersModal';
