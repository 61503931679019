export enum Dimensions {
  DIMENSION_MAKE = 'dimension1',
  DIMENSION_FUEL_TYPE = 'dimension2',
  DIMENSION_RECOMMENDED = 'dimension3',
  DIMENSION_IN_COVERAGE_AREA = 'dimension4',
  DIMENSION_VEHICLES_COUNT = 'dimension6',
  DIMENSION_URGENCY = 'dimension7',
  DIMENSION_FIRST_COLLECTION_TIME = 'dimension15',
  DIMENSION_GARAGE_CD_2H_TIME_SLOT = 'dimension16',
  DIMENSION_POSTCODE_LANDING = 'dimension17',
  DIMENSION_TIME_TO_COMPLETE_CHECKOUT = 'dimension18',
  DIMENSION_NEXT_SLOT = 'dimension19',
  DIMENSION_COVERAGE_TYPE = 'dimension20',

  METRIC_VEHICLE_AGE = 'dimension8',
  METRIC_DAYS_TO_MOT = 'dimension10',
  METRIC_LAST_KNOWN_MILEAGE = 'dimension13',
  METRIC_ENGINE_SIZE = 'dimension14',

  METRIC_MARKET_PRICE = 'metric8',
}
