/* istanbul ignore file */
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { createGlobalStyle } from 'styled-components';
import { isEnvDev } from '../index';
import { getComponentName } from '../component';
import type { MediaQueryBreakpoint } from './breakpoints';
import { DEFAULT_BREAKPOINTS } from './breakpoints';

/**
 * @deprecated use `src/styles/variables/_breakpoints.scss`
 */
let MEDIA_QUERY_BREAKPOINTS: Map<string, number>;
export const setMediaQueryBreakpoints = (breakpoints: Record<string, number>): void => {
  MEDIA_QUERY_BREAKPOINTS = new Map(Object.entries(breakpoints).sort((a, b) => a[1] - b[1]));
};
setMediaQueryBreakpoints(DEFAULT_BREAKPOINTS);

const getInverseMediaQuery = (minWidth?: number, maxWidth?: number) => {
  const queries = [];
  if (minWidth) queries.push(`(max-width: ${minWidth - 1}px)`);
  if (maxWidth) queries.push(`(min-width: ${maxWidth}px)`);
  const mediaQuery = queries.join(', ');
  return mediaQuery;
};

/**
 * Media queries data for all breakpoints combinations
 */
const mediaQueryCombinations = new Map();
MEDIA_QUERY_BREAKPOINTS!.forEach((width, breakpoint) => {
  if (!width) return;
  mediaQueryCombinations.set(`from-${breakpoint}`, getInverseMediaQuery(width));
  mediaQueryCombinations.set(`to-${breakpoint}`, getInverseMediaQuery(NaN, width));
  MEDIA_QUERY_BREAKPOINTS.forEach((maxWidth, breakpointTo) => {
    if (width >= maxWidth) return;
    mediaQueryCombinations.set(
      `from-${breakpoint}_to-${breakpointTo}`,
      getInverseMediaQuery(width, maxWidth)
    );
  });
});

export const getDisplayClassName = (
  displayFrom?: MediaQueryBreakpoint,
  displayTo?: MediaQueryBreakpoint
): string =>
  [displayFrom && displayFrom !== 'xs' && `from-${displayFrom}`, displayTo && `to-${displayTo}`]
    .filter((k) => k)
    .join('_');
export const WithResponsiveGlobalStyles = createGlobalStyle`
  ${() => {
    let allMediaQueries = '';
    mediaQueryCombinations.forEach((query, className) => {
      allMediaQueries += `@media ${query} { .${className} { display: none !important; } }`;
    });
    return allMediaQueries;
  }}
`;

const mergeProps = (props = {}, defaultProps = {}) => ({ ...defaultProps, ...props });

/**
 * @deprecated use `src/styles/mixins/_mediaqueries.scss`
 */
export function withResponsive(WrappedComponent: React.ElementType, defaultProps = {}): React.ElementType {
  function ResponsiveWrapper(props: any): any {
    // @ts-ignore next-line
    const { displayFrom, displayTo, className, ...componentProps } = mergeProps(props, defaultProps);
    const classes = useMemo(
      () => [getDisplayClassName(displayFrom, displayTo), className].filter((c) => c).join(' '),
      [displayFrom, displayTo, className]
    );
    if (!displayFrom && !displayTo) {
      if (isEnvDev) {
        const componentName = getComponentName(WrappedComponent as ReactNode);
        console.warn(
          'Warning: Not optimal to use "withResponsive" without display boundaries',
          componentName ? `on "${componentName}"` : ''
        );
      }
    }
    return <WrappedComponent {...componentProps} className={classes} />;
  }
  return ResponsiveWrapper;
}
