import { CollectionDeliveryTypes } from '$util/types';
import type { QueryGetTransportMethodSelectorOptions, TransportMethod } from './gql';

export const getCompactedTransportMethods = (
  transportMethods: TransportMethod[]
): CollectionDeliveryTypes[] =>
  transportMethods
    ?.filter(({ isAvailable }: TransportMethod): boolean => isAvailable)
    ?.sort((a: TransportMethod, b: TransportMethod): number => a.priority - b.priority)
    ?.map(({ transportMethod }: TransportMethod): CollectionDeliveryTypes => transportMethod);

export const getTransportOptions = (
  data: QueryGetTransportMethodSelectorOptions
): CollectionDeliveryTypes[] => {
  const transportMethods: TransportMethod[] = data?.getTransportationMethodsForBooking?.transportMethods;
  if (Array.isArray(transportMethods)) {
    return getCompactedTransportMethods(transportMethods);
  }
  return [];
};

/**
 * Here we want to record which coverage type was available on the session.
 * There are some implicit rules around how and when these values should be sent. For example:
 * When FixterC&D, we assume these are available FixterC&D, GarageC&D, CustomerC&D
 * When GarageC&D, we assume these are available GarageC&D, CustomerC&D
 * When CustomerC&D, we assume these are available CustomerC&D
 *
 * We set the coverage option on the session, it should use the latest value.
 */
export const getCoverageTypeFromTransportMethods = (
  availableTransportMethods: TransportMethod[]
): CollectionDeliveryTypes => {
  const transportMethod: TransportMethod | undefined = [...availableTransportMethods]
    .sort((a: TransportMethod, b: TransportMethod): number => a.priority - b.priority)
    .find(({ isAvailable }: TransportMethod): boolean => isAvailable);
  if (transportMethod) {
    return transportMethod.transportMethod;
  }
  return CollectionDeliveryTypes.OUT_OF_COVERAGE;
};
