import classNames from 'classnames';
import styles from './index.module.scss';

interface ScrollIndicatorProps {
  items: number;
  current?: number;
  className?: string;
}
// @QUESTION should we guard current value to be 1 >= current <= items?
export function ScrollIndicator({ items, current = 1, className }: ScrollIndicatorProps): JSX.Element {
  const dots = Array.from({
    length: items,
  });

  return (
    <ol className={classNames(ScrollIndicator.displayName, styles.scrollIndicator, className)}>
      {dots.map((_item, i) => (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={
            classNames(styles.scrollIndicatorItem, current === i + 1 && [styles.current, 'current']) ||
            undefined
          }
        />
      ))}
    </ol>
  );
}
ScrollIndicator.displayName = 'ScrollIndicator';
