'use client';

import type { ReactNode } from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import { useResizeViewport } from '$util/hooks/useResizeViewport';

import { Accordion } from '$quantum/molecules/Accordion';
import { ContentComponentSection } from '$src-components/atoms/ContentComponentSection';
import { Row, Col } from '$src-components/atoms/Grid';

import styles from './index.module.scss';

export type SectionItem = {
  readonly title: string;
  readonly content: ReactNode;
};

type FaqCollectionProps = {
  readonly title: string;
  readonly sectionItems: SectionItem[];
  readonly desktopHeadings: string[];
  readonly className?: string;
};

export function FaqCollection({
  title,
  sectionItems,
  desktopHeadings,
  className,
  ...props
}: FaqCollectionProps): ReactNode {
  const [mobile, setMobile] = useState(false);

  useResizeViewport({
    breakpoint: 'md',
    direction: 'down',
    fn: (isMobile: boolean) => setMobile(isMobile),
  });

  return (
    <ContentComponentSection
      className={classNames(FaqCollection.displayName, styles.component, className)}
      {...props}
    >
      <Row className={mobile ? 'mobile' : 'desktop'}>
        {title && (
          <Col>
            <h2>{title}</h2>
          </Col>
        )}
        {mobile ? (
          <Col>
            <Accordion accordionItems={sectionItems} />
          </Col>
        ) : (
          <>
            <Col large={4} className="menu-col">
              <aside>
                {desktopHeadings.map((heading) => {
                  const hId = kebabCase(heading);
                  return (
                    <a key={hId} href={`#${hId}`}>
                      {heading}
                    </a>
                  );
                })}
              </aside>
            </Col>
            <Col large={7} largeStart={6}>
              {sectionItems.map(({ content }) => content)}
            </Col>
          </>
        )}
      </Row>
    </ContentComponentSection>
  );
}

FaqCollection.displayName = 'FaqCollection';
