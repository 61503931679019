import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';
import classNames from 'classnames';

import { Card } from '@/components/atoms/Card';
import styles from './index.module.scss';

export interface FixieCardProps extends ComponentPropsWithoutRef<'div'> {}

export function FixieCard({ className, children, ...props }: FixieCardProps): JSX.Element {
  return (
    <Card
      elevation={1}
      cornerRadius={3}
      className={classNames(FixieCard.displayName, styles.component, className)}
      {...props}
    >
      <div>{children}</div>
    </Card>
  );
}
FixieCard.displayName = 'FixieCard';
