import type { FlattenInterpolation, ThemeProps } from 'styled-components';
import { css } from 'styled-components';

export type SpacingProperty = 'top' | 'bottom';

export enum HorizontalSpacerHeights {
  L = 112,
  M = 80,
  S = 56,
  XS = 32,
  XXS = 16,
  INLINE = 8,
}

export const hSpacer = (
  edge: SpacingProperty,
  size: keyof typeof HorizontalSpacerHeights
): FlattenInterpolation<
  ThemeProps<{
    type: SpacingProperty;
    size: keyof typeof HorizontalSpacerHeights;
  }>
> => css`
  ${(): string => `margin-${edge}: ${HorizontalSpacerHeights[size]}px;`}
`;

export const hPadder = (
  edge: SpacingProperty,
  size: keyof typeof HorizontalSpacerHeights
): FlattenInterpolation<
  ThemeProps<{
    type: SpacingProperty;
    size: keyof typeof HorizontalSpacerHeights;
  }>
> => css`
  ${(): string => `padding-${edge}: ${HorizontalSpacerHeights[size]}px;`}
`;
