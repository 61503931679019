import type { HTMLAttributes } from 'react';
import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export type ContentComponentSectionProps = HTMLAttributes<HTMLDivElement>;

/**
 * Base component that ContentComponents can start on for consistent spacing across page.
 */
export function ContentComponentSection({
  className,
  children,
  ...props
}: ContentComponentSectionProps): JSX.Element {
  return (
    <section className={classNames(styles.component, className)} {...props}>
      {children}
    </section>
  );
}
ContentComponentSection.displayName = 'ContentComponentSection';
