'use client';

import React from 'react';
import config from 'config';
import { useRenderAfterHydration } from '$util/hooks/useRenderAfterHydration';
import { LiveChat as LiveChatApp } from '@/components/templates/ThirdPartyScripts/LiveChat';

const isApiReady = () => {
  const api = window.LC_API;
  if (api && api.get_window_type()) return api;
  return false;
};

export const showLiveChat = () => {
  const chatBubble = document.getElementById('chat-widget-container');

  if (chatBubble) chatBubble.style.display = 'block';

  setTimeout(() => {
    try {
      const api = isApiReady();
      if (api) api.open_chat_window();
    } catch (err) {
      console.error(err);
    }
  }, 500);
};

export const minimiseLiveChat = () => {
  setTimeout(() => {
    try {
      const api = isApiReady();
      if (api) api.minimize_chat_window();
    } catch (err) {
      console.warn('err', err);
    }
  }, 500);
};

/**
 * @deprecated - in app router we integrate it through next/script inside ThirdPartyScripts
 */
export default function LiveChat({ pageType }) {
  const livechatId = config.get('public.livechat_id');
  const enabled = config.get('public.livechat.enabled');
  const delay = config.get('public.livechat.delayLoadBy') || 0;

  const renderCSR = useRenderAfterHydration(() => enabled && livechatId && pageType !== 'widget');

  if (!renderCSR) return null;
  return <LiveChatApp livechatId={livechatId} delayLoad={delay} />;
}
