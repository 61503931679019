import { useEffect, useState } from 'react';

/**
 * Avoid rendering conditionally only on client based on `process.browser` or other client specific condition
 * because it creates mismatch between SSR and CSR which might create weird hydration issues.
 * Eg. error "Expected server HTML to contain a matching <div> in <div>".
 * @see https://github.com/vercel/next.js/discussions/17443#discussioncomment-87097
 */
export const useRenderAfterHydration = (effectFn?: () => boolean): boolean => {
  const [renderCSR, setRenderCSR] = useState(false);

  useEffect(() => {
    if (!effectFn || effectFn()) setRenderCSR(true);
  }, [effectFn]);

  return renderCSR;
};
