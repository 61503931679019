import { getCurrency } from '@fixter/i18n';
import config from 'config';
import qs from 'qs';

import { get } from 'lodash';
import { isBrowser } from '$util/index';
import type { AnyObject } from '$util/types';
import type { IVehicle } from '$apollo/gql/vehicle/types';
import type { TransportMethod } from '@/components/organisms/TransportMethodSelector/gql';
import { getCoverageTypeFromTransportMethods } from '@/components/organisms/TransportMethodSelector/helpers';
import { ga4Event, ga4Set } from '@/util/ga4Analytics';
import type {
  ProductsListAnalytics,
  PromotionParameters,
  PurchaseParameters,
  SuccessPageDisplayedEvent,
} from './types';
import { AnalyticsEvents } from './types';
import { basketProductMetrics, CheckoutStep, getHoursUntilNextSlot, productToMetrics } from './helpers';
import { Dimensions } from './dimensions';
import type { ProductFieldObject } from './enhanced-ecommerce';

const ga4Enabled = isBrowser && config.get('public.ga4.enabled');

const queryParams = isBrowser && qs.parse(window.location.search, { ignoreQueryPrefix: true });

/**
 * Type safe wrapper function that switches off (noops), or pipes events elsewhere
 *
 * This is preferred over the switch below which is very verbose and tricky to navigate quickly with the right types
 */
const noopWhenGA4IsOff = <T, R = unknown>(cb: (data: T) => R): ((data: T) => R | undefined) => {
  return (data: T): R | undefined => {
    if (ga4Enabled) {
      return cb(data);
    }
    return undefined;
  };
};

export const sendPageViewWithDimensions = (): void => {
  if (!ga4Enabled) return;

  let pageViewDimensions = {};
  if (queryParams) {
    pageViewDimensions = {
      dimension3: queryParams.itm_widget,
      dimension4: queryParams.itm_wo,
      dimension7: queryParams.itm_article,
    };
    ga4Set(pageViewDimensions);
  }

  ga4Event('page_view', {
    page_path: window.location.pathname + window.location.search,
    ...pageViewDimensions,
  });
};

type ConsentOptions = 'denied' | 'granted';

export const setConsentForOneTrustCategories = (
  analytics: ConsentOptions,
  ad: ConsentOptions,
  functional: ConsentOptions
): void => {
  const consent = {
    analytics_storage: analytics,
    ad_storage: ad,
    ad_user_data: ad,
    ad_personalization: ad,
    functionality_storage: functional,
  };

  window.gtag('consent', 'update', consent);
};

const partsQualityQuotesExplainerClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Parts quality quotes explainer clicked', {
    event_category: AnalyticsEvents.PARTS_QUALITY_QUOTES_EXPLAINER_CLICKED,
  });
});

const extraWorkQuotesExplainerClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Extra work quotes explainer clicked', {
    event_category: AnalyticsEvents.EXTRA_WORK_QUOTES_EXPLAINER_CLICKED,
  });
});

const cndExplainerScheduleClicked = noopWhenGA4IsOff((): void => {
  ga4Event('C&D explainer Schedule clicked', {
    event_category: AnalyticsEvents.CnD_EXPLAINER_SCHEDULE_CLICKED,
  });
});

const insuranceExplainerScheduleClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Insurance explainer schedule clicked', {
    event_category: AnalyticsEvents.INSURANCE_EXPLAINER_SCHEDULE_CLICKED,
  });
});

const driverExplainerScheduleClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Driver explainer schedule clicked', {
    event_category: AnalyticsEvents.DRIVER_EXPLAINER_SCHEDULE_CLICKED,
  });
});

const garageExplainerScheduleClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Garage explainer schedule clicked', {
    event_category: AnalyticsEvents.GARAGE_EXPLAINER_SCHEDULE_CLICKED,
  });
});

const bookNowPayLaterClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Book now pay later clicked', {
    event_category: AnalyticsEvents.BOOK_NOW_PAY_LATER_CLICKED,
  });
});

const trackBookingComponentDisplayed = (source: string | undefined) => {
  ga4Event('Track booking component displayed', {
    event_category: AnalyticsEvents.TRACK_BOOKING_COMPONENT_DISPLAYED,
    event_label: source,
  });
};

const trackMyBookingClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Track my booking clicked', {
    event_category: AnalyticsEvents.TRACK_MY_BOOKING_CLICKED,
  });
});

const trackHelpCardContactUsClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Track help card - contact us clicked', {
    event_category: AnalyticsEvents.TRACK_HELP_CARD_CONTACT_US_CLICKED,
  });
});

const trackHelpCardFaqClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Track help card - faq clicked', {
    event_category: AnalyticsEvents.TRACK_HELP_CARD_FAQ_CLICKED,
  });
});

const priceMatchPromiseClicked = noopWhenGA4IsOff(
  ({ title, sku }: { readonly title: string; readonly sku: string }): void => {
    ga4Event(`${title} link clicked on ${sku}`, {
      event_category: AnalyticsEvents.PRICE_MATCH_PROMISE_CLICKED,
    });
  }
);

const moneyBackGuaranteeClicked = noopWhenGA4IsOff((title: string): void => {
  ga4Event(`${title} link clicked`, {
    event_category: AnalyticsEvents.MONEY_BACK_GUARANTEE_CLICKED,
  });
});

const seeMapClicked = noopWhenGA4IsOff((): void => {
  ga4Event('See map button clicked', {
    event_category: AnalyticsEvents.SEE_MAP_CLICKED,
  });
});

const getDirectionsClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Get directions button clicked', {
    event_category: AnalyticsEvents.SEE_MAP_CLICKED,
  });
});

const optionalCnDCustomerClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Optional C&D - Customer C&D selected', {
    event_category: AnalyticsEvents.OPTIONAL_CnD_CUSTOMER_CLICKED,
  });
});

const fixterWarrantyQuotesExplainerClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Fixter warranty quotes explainer clicked', {
    event_category: AnalyticsEvents.FIXTER_WARRANTY_QUOTES_EXPLAINER_CLICKED,
  });
});

const vehicleLookupSuccess = (vehicle: IVehicle) => {
  const vehicleLookupSuccessDimensions = {
    [Dimensions.DIMENSION_MAKE]: get(vehicle, 'make'),
    [Dimensions.DIMENSION_FUEL_TYPE]: get(vehicle, 'fuelType'),
    [Dimensions.METRIC_ENGINE_SIZE]: get(vehicle, 'litre'),
    [Dimensions.METRIC_VEHICLE_AGE]: get(vehicle, 'vehicleAge'),
  };
  ga4Set(vehicleLookupSuccessDimensions);
  ga4Event('Lookup Success', {
    event_category: AnalyticsEvents.EVENT_VEHICLE,
    event_label: vehicle.vrm,
    ...vehicleLookupSuccessDimensions,
  });
};

const outOfCoverageDisplayed = noopWhenGA4IsOff((postcode: string): void => {
  ga4Event('Out of coverage area component appeared', {
    event_category: AnalyticsEvents.OUT_OF_COVERAGE,
    event_label: postcode,
  });
});

const vehicleLookupError = (vrm: string) => {
  ga4Event('Lookup Error', {
    event_category: AnalyticsEvents.EVENT_VEHICLE,
    event_label: vrm,
  });
};

const productDisplayed = (productAnalyticsData: ProductsListAnalytics) => {
  ga4Event('Product displayed', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
    ...productToMetrics(productAnalyticsData),
  });
};

const motServicesTabDisplayed = () => {
  ga4Event('Mot and Services list displayed', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
  });
};
const servicesTabDisplayed = () => {
  ga4Event('Services list displayed', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
  });
};

const diagnosticsTabDisplayed = () => {
  ga4Event('Diagnostics list displayed', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
  });
};

const repairsTabDisplayed = () => {
  ga4Event('Repairs list displayed', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
  });
};

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#add_to_cart
const addToBasket = (data: Record<string, any>) => {
  ga4Event('Add to cart', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
    ...basketProductMetrics(data),
  });
};

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=2991237032793595114-EU&client_type=gtm#select_promotion
const applyPromotion = (coupon: string) => {
  const payload: PromotionParameters = {
    promotion_id: coupon,
    promotion_name: coupon,
    id: coupon,
    name: coupon,
  };
  ga4Event('Select promotion', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
    ...payload,
  });
};

const callbackRequested = () => {
  ga4Event('Call back request', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
  });
};

const valuePropositionVideoClicked = () => {
  ga4Event('Value proposition video clicked', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
  });
};

const getInstantQuoteModalClicked = () => {
  ga4Event('Get instant quote modal clicked', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
  });
};

const offlineQuoteModalDisplayed = () => {
  ga4Event('Offline quote modal displayed', {
    event_category: AnalyticsEvents.OFFLINE_QUOTE_REQUEST_DISPLAYED,
  });
};

const quoteIncludingPriceRange = () => {
  ga4Event('Quote page is showing price ranges', {
    event_category: AnalyticsEvents.PRICE_RANGE_DISPLAYED,
  });
};

const saveQuoteBannerDisplayed = () => {
  ga4Event('Save quote banner displayed', {
    event_category: AnalyticsEvents.SAVE_QUOTE_BANNER_DISPLAYED,
  });
};

const saveQuoteBannerDismissed = () => {
  ga4Event('Save quote banner dismissed', {
    event_category: AnalyticsEvents.SAVE_QUOTE_BANNER_DISMISSED,
  });
};

const saveQuoteBannerSubmitted = () => {
  ga4Event('Save quote banner submitted', {
    event_category: AnalyticsEvents.SAVE_QUOTE_BANNER_SUBMITTED,
  });
};

const downloadServiceSheetClicked = () => {
  ga4Event('Download service sheet clicked', {
    event_category: AnalyticsEvents.DOWNLOAD_SERVICE_SHEET_CLICKED,
  });
};

const quotesPageDisplayed = (mountedTimeInMs: number) => {
  ga4Event('Quotes page displayed', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
    step: CheckoutStep.QUOTES,
    value: mountedTimeInMs,
  });
};

const schedulePageDisplayed = () => {
  ga4Event('Schedule page displayed', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
    step: CheckoutStep.SCHEDULE,
  });
};

const setSessionCoverageType = noopWhenGA4IsOff((availableTransportMethods: TransportMethod[]) => {
  const sessionCoverageType = getCoverageTypeFromTransportMethods(availableTransportMethods);
  ga4Set({
    [Dimensions.DIMENSION_COVERAGE_TYPE]: sessionCoverageType,
  });

  // @NOTE event to track coverage type in GA4
  ga4Event('Coverage type determined', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
    step: CheckoutStep.SCHEDULE,
    [Dimensions.DIMENSION_COVERAGE_TYPE]: sessionCoverageType,
  });
  return sessionCoverageType;
});

const collectionTimeDisplayed = (timetable: AnyObject) => {
  const hoursUntilNextSlot = getHoursUntilNextSlot(timetable);

  ga4Set({
    [Dimensions.DIMENSION_FIRST_COLLECTION_TIME]: get(timetable, 'collection.timeStart', ''),
  });

  ga4Set({
    [Dimensions.DIMENSION_NEXT_SLOT]: hoursUntilNextSlot,
  });

  ga4Event('Collection times loaded', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
    step: CheckoutStep.SCHEDULE,
    [Dimensions.DIMENSION_FIRST_COLLECTION_TIME]: get(timetable, 'collection.timeStart', ''),
    [Dimensions.DIMENSION_NEXT_SLOT]: hoursUntilNextSlot,
  });
};

const personalDetailsPageDisplayed = () => {
  ga4Event('Personal details displayed', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
    step: CheckoutStep.PERSONAL_DETAILS,
    event_label: 'personal_details_page_displayed',
  });
};

const paymentPageDisplayed = () => {
  ga4Event('Payment displayed', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
    event_label: 'payment_page_displayed',
  });
};

const successPageDisplayed = noopWhenGA4IsOff<SuccessPageDisplayedEvent>(
  ({ bookingId, total, vat, promotionCode, basket, items, segment }) => {
    /**
     * Execution order of ReactGA commands is relevant.
     *
     * Valid order:
     * - executeAddPromo (optional)
     * - executeSetActionAddProduct (optional)
     * - executeSetActionPurchase
     * - eventCompleteBooking
     * - executeSetActionCheckout
     * - eventSuccessDisplayed
     */

    const analyticsProcessSteps = new Map();

    const currency = getCurrency(segment);
    if (promotionCode) {
      analyticsProcessSteps.set('executeAddPromo', (): void => {
        const payload: PromotionParameters = {
          promotion_id: promotionCode,
          promotion_name: promotionCode,
          id: promotionCode,
          name: promotionCode,
        };

        ga4Event('Add promotion', {
          event_category: AnalyticsEvents.EVENT_ECOMMERCE,
          ...payload,
        });
      });
    }

    if (Array.isArray(basket)) {
      analyticsProcessSteps.set('executeSetActionAddProduct', () => {
        return basket.map((item: ProductFieldObject): void =>
          ga4Event('Add product', {
            event_category: AnalyticsEvents.EVENT_ECOMMERCE,
            ...item,
          })
        );
      });
    }

    analyticsProcessSteps.set('executeSetActionPurchase', (): void => {
      const payload: PurchaseParameters = {
        id: `booking-${bookingId}`,
        affiliation: 'Fixter Website',
        step: CheckoutStep.SUCCESS,
        revenue: total,
        currency,
        transaction_id: String(bookingId),
        value: Number(total),
        coupon: promotionCode,
        tax: Number(vat),
        items,
      };
      /**
       * **** Used for reports ****
       */
      ga4Event('Purchase', {
        event_category: AnalyticsEvents.EVENT_ECOMMERCE,
        ...payload,
      });
      /**
       * **** Used for revenue ****
       * GA4 needs the "purchase" recommended event in order to calculate revenue
       * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#purchase
       */
      ga4Event('purchase', {
        event_category: AnalyticsEvents.EVENT_ECOMMERCE,
        ...payload,
      });
    });

    analyticsProcessSteps.set('eventCompleteBooking', (): void => {
      return ga4Event('Complete booking', {
        event_category: AnalyticsEvents.EVENT_ECOMMERCE,
        event_label: 'complete booking',
        value: Number(total),
        currency,
      });
    });

    analyticsProcessSteps.set('executeSetActionCheckout', (): void => {
      ga4Event('Purchase success', {
        event_category: AnalyticsEvents.EVENT_ECOMMERCE,
        step: CheckoutStep.SUCCESS,
      });
    });

    analyticsProcessSteps.set('eventSuccessDisplayed', () => {
      return ga4Event('Success displayed', {
        event_category: AnalyticsEvents.EVENT_ECOMMERCE,
        event_label: 'Success_page_displayed',
      });
    });

    analyticsProcessSteps.forEach((_, key, map): void => {
      map.get(key)();
    });

    return Array.from(analyticsProcessSteps.keys());
  }
);

const bundlePageDisplayed = (sku: string): void => {
  let eventAction = 'Bundle displayed';
  if (sku && sku !== 'full-service') eventAction = `Extra step (${sku}) - Displayed`;
  ga4Event(eventAction, {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
    event_label: 'bundle_page_displayed',
    step: CheckoutStep.BUNDLE,
  });
};

const bundlePageAdded = (sku: string): void => {
  ga4Event(`${sku} - Added`, {
    event_category: AnalyticsEvents.BUNDLE_PAGE_ADDED,
  });
};

const bundlePageSkipped = (sku: string): void => {
  ga4Event(`${sku} - No thanks`, {
    event_category: AnalyticsEvents.BUNDLE_PAGE_ADDED,
  });
};

const optionalCnDNoEligibleGarages = noopWhenGA4IsOff((data: any): void => {
  ga4Event('Optional C&D - No eligible garages', {
    event_category: AnalyticsEvents.OPTIONAL_CnD_NO_ELIGIBLE_GARAGES,
    event_label: data,
  });
});

const cnD3EligibleGarages = () => {
  ga4Event('Optional C&D - 3 eligible garages', {
    event_category: AnalyticsEvents.CnD_3_ELIGIBLE_GARAGES,
  });
};

const cnD2EligibleGarages = () => {
  ga4Event('Optional C&D - 2 eligible garages', {
    event_category: AnalyticsEvents.CnD_2_ELIGIBLE_GARAGES,
  });
};

const cnD1EligibleGarages = () => {
  ga4Event('Optional C&D - 1 eligible garages', {
    event_category: AnalyticsEvents.CnD_1_ELIGIBLE_GARAGES,
  });
};

const cnD3TotalGarages = () => {
  ga4Event('Optional C&D - 3 total garages', {
    event_category: AnalyticsEvents.CnD_3_TOTAL_GARAGES,
  });
};

const cnD2TotalGarages = () => {
  ga4Event('Optional C&D - 2 total garages', {
    event_category: AnalyticsEvents.CnD_2_TOTAL_GARAGES,
  });
};

const cnD1TotalGarages = () => {
  ga4Event('Optional C&D - 1 total garages', {
    event_category: AnalyticsEvents.CnD_1_TOTAL_GARAGES,
  });
};

const optionalCnDAverageDistanceEligibleGarages = noopWhenGA4IsOff((data: any): void => {
  ga4Event(`Optional C&D - average distance eligible garages: ${data}`, {
    event_category: AnalyticsEvents.OPTIONAL_CnD_AVERAGE_DISTANCE_ELIGIBLE_GARAGES,
    event_label: data,
  });
});

const optionalCnDClosestDistanceEligibleGarages = noopWhenGA4IsOff((data: any): void => {
  ga4Event(`Optional C&D - closest distance eligible garages: ${data}`, {
    event_category: AnalyticsEvents.OPTIONAL_CnD_CLOSEST_DISTANCE_ELIGIBLE_GARAGES,
    event_label: data,
  });
});

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#remove_from_cart
const removeFromBasket = (data: Record<string, any>): void => {
  ga4Event('Remove from cart', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
    ...basketProductMetrics(data),
  });
};

const salesModalClicked = (label: string): void => {
  ga4Event('Sales modal', {
    event_category: AnalyticsEvents.SALES_MODAL,
    event_label: label,
  });
};

const salesModalCtaClicked = (label: string): void => {
  ga4Event('Sales modal cta', {
    event_category: AnalyticsEvents.SALES_MODAL_CTA,
    event_label: label,
  });
};

const addToCalendarClicked = (type: string): void => {
  ga4Event(AnalyticsEvents.ADD_TO_CALENDAR_CLICKED, {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
    event_label: type,
  });
};

const footerFaqClicked = (): void => {
  ga4Event('Footer FAQ Link Clicked', {
    event_category: AnalyticsEvents.FOOTER_FAQ_CLICKED,
    event_label: 'footer_faq_link_clicked',
  });
};

const googleReviewsSummaryClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Google reviews summary clicked', {
    event_category: AnalyticsEvents.GOOGLE_REVIEWS_SUMMARY_CLICKED,
    event_label: 'google_reviews_summary_clicked',
  });
});

const googleSendEWODangerousWOInfoExpanded = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Dangerous work operation clicked', {
    event_category: AnalyticsEvents.EWO_PROPOSED_DANGEROUS_INFO_EXPANDED,
    event_label: 'EWO_proposed_dangerous_info_expanded',
  });
});

const googleSendEWOMajorWOInfoExpanded = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Major work operation clicked', {
    event_category: AnalyticsEvents.EWO_PROPOSED_MAJOR_INFO_EXPANDED,
    event_label: 'EWO_proposed_major_info_expanded',
  });
});

const googleSendEWOMinorWOInfoExpanded = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Minor work operation clicked', {
    event_category: AnalyticsEvents.EWO_PROPOSED_MINOR_INFO_EXPANDED,
    event_label: 'EWO_proposed_minor_info_expanded',
  });
});

const googleSendEWOAdvisoryWOInfoExpanded = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Advisory work operation clicked', {
    event_category: AnalyticsEvents.EWO_PROPOSED_ADVISORY_INFO_EXPANDED,
    event_label: 'EWO_proposed_advisory_info_expanded',
  });
});

const googleSendEWOProposedHereToHelpClicked = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed Here to help clicked', {
    event_category: AnalyticsEvents.EWO_PROPOSED_HERE_TO_HELP_CLICKED,
    event_label: 'EWO_proposed_here_to_help_clicked',
  });
});

const googleSendEWOPaymentHereToHelpClicked = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Payment Here to help clicked', {
    event_category: AnalyticsEvents.EWO_PAYMENT_HERE_TO_HELP_CLICKED,
    event_label: 'EWO_payment_here_to_help_clicked',
  });
});

const googleSendEWOSuccessHereToHelpClicked = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Success Here to help clicked', {
    event_category: AnalyticsEvents.EWO_SUCCESS_HERE_TO_HELP_CLICKED,
    event_label: 'EWO_success_here_to_help_clicked',
  });
});

const googleSendEWOProposedImageClicked = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed Image clicked', {
    event_category: AnalyticsEvents.EWO_PROPOSED_IMAGE_CLICKED,
    event_label: 'EWO_proposed_image_clicked',
  });
});

const googleSendEWODangerousCallUsClicked = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed dangerous work operation Call Us clicked', {
    event_category: AnalyticsEvents.EWO_PROPOSED_DANGEROUS_WO_CALL_US_CLICKED,
    event_label: 'EWO_proposed_dangerous_call_us_clicked',
  });
});

const googleSendEWOMajorCallUsClicked = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed major work operation Call Us clicked', {
    event_category: AnalyticsEvents.EWO_PROPOSED_MAJOR_WO_CALL_US_CLICKED,
    event_label: 'EWO_proposed_major_call_us_clicked',
  });
});

const googleSendEWODangerousAccepted = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed dangerous work operation accepted', {
    event_category: AnalyticsEvents.EWO_PROPOSED_DANGEROUS_ACCEPTED,
    event_label: 'EWO_proposed_dangerous_accepted',
  });
});

const googleSendEWOMajorAccepted = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed major work operation accepted', {
    event_category: AnalyticsEvents.EWO_PROPOSED_MAJOR_ACCEPTED,
    event_label: 'EWO_proposed_major_accepted',
  });
});

const googleSendEWOMinorAccepted = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed minor work operation accepted', {
    event_category: AnalyticsEvents.EWO_PROPOSED_MINOR_ACCEPTED,
    event_label: 'EWO_proposed_minor_accepted',
  });
});

const googleSendEWOAdvisoryAccepted = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed advisory work operation accepted', {
    event_category: AnalyticsEvents.EWO_PROPOSED_ADVISORY_ACCEPTED,
    event_label: 'EWO_proposed_advisory_accepted',
  });
});

const googleSendEWODangerousDeclined = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed dangerous work operation declined', {
    event_category: AnalyticsEvents.EWO_PROPOSED_DANGEROUS_DECLINED,
    event_label: 'EWO_proposed_dangerous_declined',
  });
});

const googleSendEWOMajorDeclined = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed major work operation declined', {
    event_category: AnalyticsEvents.EWO_PROPOSED_MAJOR_DECLINED,
    event_label: 'EWO_proposed_major_declined',
  });
});

const googleSendEWOMinorDeclined = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed minor work operation declined', {
    event_category: AnalyticsEvents.EWO_PROPOSED_MINOR_DECLINED,
    event_label: 'EWO_proposed_minor_declined',
  });
});

const googleSendEWOAdvisoryDeclined = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed advisory work operation declined', {
    event_category: AnalyticsEvents.EWO_PROPOSED_ADVISORY_DECLINED,
    event_label: 'EWO_proposed_advisory_declined',
  });
});

const googleSendEWOProposedMenuClicked = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed menu clicked', {
    event_category: AnalyticsEvents.EWO_PROPOSED_MENU_CLICKED,
    event_label: 'EWO_proposed_menu_clicked',
  });
});

const googleSendEWOProposedCallUsClicked = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed Call us clicked', {
    event_category: AnalyticsEvents.EWO_PROPOSED_CALL_US_CLICKED,
    event_label: 'EWO_proposed_call_us_clicked',
  });
});

const googleSendEWOPaymentCallUsClicked = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed Payment Call us clicked', {
    event_category: AnalyticsEvents.EWO_PROPOSED_PAYMENT_CALL_US_CLICKED,
    event_label: 'EWO_proposed_payment_call_us_clicked',
  });
});

const googleSendEWOSuccessCallUsClicked = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed Success Call us clicked', {
    event_category: AnalyticsEvents.EWO_PROPOSED_SUCCESS_CALL_US_CLICKED,
    event_label: 'EWO_proposed_success_call_us_clicked',
  });
});

const googleSendEWOProposedConfirmClicked = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Proposed Confirm button clicked', {
    event_category: AnalyticsEvents.EWO_PROPOSED_CONFIRM_BUTTON_CLICKED,
    event_label: 'EWO_proposed_confirm_button_clicked',
  });
});

const googleSendEWOPaymentConfirmClicked = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Payment Confirm button clicked', {
    event_category: AnalyticsEvents.EWO_PAYMENT_CONFIRM_BUTTON_CLICKED,
    event_label: 'EWO_payment_confirm_button_clicked',
  });
});

const googleSendEWOPaymentWarrantyExpanded = noopWhenGA4IsOff((): void => {
  ga4Event('EWO Payment Warranty expanded', {
    event_category: AnalyticsEvents.EWO_PAYMENT_WARRANTY_EXPANDED,
    event_label: 'EWO_payment_warranty_expanded',
  });
});

const needHelpClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Need help button clicked', {
    event_category: AnalyticsEvents.NEED_HELP_BUTTON_CLICKED,
    event_label: 'Need help button clicked',
  });
});

const needHelpModalCallUsClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Need help modal CallUs button clicked', {
    event_category: AnalyticsEvents.NEED_HELP_MODAL_CALL_US_CLICKED,
    event_label: 'Need help modal CallUs button clicked',
  });
});

const needHelpModalLiveChatClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Need help modal LiveChat button clicked', {
    event_category: AnalyticsEvents.NEED_HELP_MODAL_LIVE_CHAT_CLICKED,
    event_label: 'Need help modal LiveChat button clicked',
  });
});

const needHelpModalEmailUsClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Need help modal EmailUs button clicked', {
    event_category: AnalyticsEvents.NEED_HELP_MODAL_EMAIL_US_CLICKED,
    event_label: 'Need help modal EmailUs button clicked',
  });
});

const needHelpModalContactFormClicked = noopWhenGA4IsOff((): void => {
  ga4Event('Need help modal Contact Form button clicked', {
    event_category: AnalyticsEvents.NEED_HELP_MODAL_CONTACT_FORM_CLICKED,
    event_label: 'Need help modal Contact Form button clicked',
  });
});

const needHelpModalFAQClicked = noopWhenGA4IsOff((question: string): void => {
  ga4Event('Need help modal FAQ button clicked', {
    event_category: AnalyticsEvents.NEED_HELP_MODAL_FAQ_CLICKED,
    event_label: question,
  });
});

const editBookingButtonClicked = noopWhenGA4IsOff((bookingId: string): void => {
  ga4Event('Edit Booking Button Clicked', {
    event_category: AnalyticsEvents.EDIT_BOOKING_BUTTON_CLICKED,
    event_label: bookingId,
  });
});

const downloadInvoiceButtonClicked = noopWhenGA4IsOff((bookingId: string): void => {
  ga4Event('Download Invoice Button Clicked', {
    event_category: AnalyticsEvents.DOWNLOAD_INVOICE_BUTTON_CLICKED,
    event_label: bookingId,
  });
});

const downloadServiceSheetButtonClicked = noopWhenGA4IsOff((bookingId: string): void => {
  ga4Event('Download Service Sheet Button Clicked', {
    event_category: AnalyticsEvents.DOWNLOAD_SERVICE_SHEET_BUTTON_CLICKED,
    event_label: bookingId,
  });
});

const viewMotCertificateButtonClicked = noopWhenGA4IsOff((bookingId: string): void => {
  ga4Event('View MOT Certificate Button Clicked', {
    event_category: AnalyticsEvents.VIEW_MOT_CERTIFICATE_BUTTON_CLICKED,
    event_label: bookingId,
  });
});

const motPageDisplayed = noopWhenGA4IsOff(() => {
  ga4Event('Mot page displayed', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
  });
});

const servicesPageDisplayed = noopWhenGA4IsOff(() => {
  ga4Event('Services page displayed', {
    event_category: AnalyticsEvents.EVENT_ECOMMERCE,
  });
});

const upsellClickedOnWhatsIncluded = noopWhenGA4IsOff((event): void => {
  ga4Event('Upsell clicked on whats included', {
    event_category: AnalyticsEvents.UPSELL_CLICKED_ON_WHATS_INCLUDED,
    event_label: event,
  });
});

const fixterGuaranteesClicked = noopWhenGA4IsOff((event): void => {
  ga4Event('Fixter guarantees clicked', {
    event_category: AnalyticsEvents.FIXTER_GUARANTEES_CLICKED,
    event_label: event,
  });
});

const productFullDescriptionClicked = noopWhenGA4IsOff((event): void => {
  ga4Event('Full description clicked', {
    event_category: AnalyticsEvents.FULL_DESCRIPTION_CLICKED,
    event_label: event,
  });
});

const checkListClicked = noopWhenGA4IsOff((event): void => {
  ga4Event('Check list clicked', {
    event_category: AnalyticsEvents.CHECK_LIST_CLICKED,
    event_label: event,
  });
});

const oilEngineClicked = noopWhenGA4IsOff((event): void => {
  ga4Event('Oil engine clicked', {
    event_category: AnalyticsEvents.OIL_ENGINE_CLICKED,
    event_label: event,
  });
});

const needOtherServicesClicked = noopWhenGA4IsOff((event): void => {
  ga4Event('Need other services clicked', {
    event_category: AnalyticsEvents.NEED_OTHER_SERVICES_CLICKED,
    event_label: event,
  });
});

const productTabClicked = noopWhenGA4IsOff((event): void => {
  ga4Event('Product tab clicked', {
    event_category: AnalyticsEvents.PRODUCT_TAB_CLICKED,
    event_label: event,
  });
});

const howItWorksCarouselStepAccessed = ({
  page,
  step,
  touchEnabled,
}: {
  readonly page: string;
  readonly step: number;
  readonly touchEnabled: boolean;
}): void => {
  ga4Event('How it works carousel step accessed', {
    event_category: AnalyticsEvents.HOW_IT_WORKS_CAROUSEL_STEP_ACCESSED,
    event_label: step,
    page,
    touchEnabled,
  });
};
/**
 * @todo this has to be optimized for three-shaking to not send to client
 * the function of events that are not used on the page
 */
export const googleAnalytics4 = {
  footerFaqClicked,
  addToCalendarClicked,
  salesModalCtaClicked,
  salesModalClicked,
  removeFromBasket,
  optionalCnDNoEligibleGarages,
  bundlePageSkipped,
  bundlePageAdded,
  bundlePageDisplayed,
  successPageDisplayed,
  paymentPageDisplayed,
  personalDetailsPageDisplayed,
  setSessionCoverageType,
  collectionTimeDisplayed,
  schedulePageDisplayed,
  quotesPageDisplayed,
  downloadServiceSheetClicked,
  saveQuoteBannerSubmitted,
  saveQuoteBannerDismissed,
  saveQuoteBannerDisplayed,
  quoteIncludingPriceRange,
  offlineQuoteModalDisplayed,
  getInstantQuoteModalClicked,
  valuePropositionVideoClicked,
  callbackRequested,
  applyPromotion,
  addToBasket,
  repairsTabDisplayed,
  diagnosticsTabDisplayed,
  motServicesTabDisplayed,
  servicesTabDisplayed,
  productDisplayed,
  vehicleLookupError,
  vehicleLookupSuccess,
  outOfCoverageDisplayed,
  fixterWarrantyQuotesExplainerClicked,
  optionalCnDCustomerClicked,
  seeMapClicked,
  getDirectionsClicked,
  moneyBackGuaranteeClicked,
  priceMatchPromiseClicked,
  bookNowPayLaterClicked,
  trackBookingComponentDisplayed,
  trackMyBookingClicked,
  trackHelpCardContactUsClicked,
  trackHelpCardFaqClicked,
  garageExplainerScheduleClicked,
  driverExplainerScheduleClicked,
  insuranceExplainerScheduleClicked,
  cndExplainerScheduleClicked,
  extraWorkQuotesExplainerClicked,
  partsQualityQuotesExplainerClicked,
  cnD3EligibleGarages,
  cnD2EligibleGarages,
  cnD1EligibleGarages,
  cnD3TotalGarages,
  cnD2TotalGarages,
  cnD1TotalGarages,
  optionalCnDAverageDistanceEligibleGarages,
  optionalCnDClosestDistanceEligibleGarages,
  googleReviewsSummaryClicked,
  googleSendEWODangerousWOInfoExpanded,
  googleSendEWOMajorWOInfoExpanded,
  googleSendEWOMinorWOInfoExpanded,
  googleSendEWOAdvisoryWOInfoExpanded,
  googleSendEWOProposedHereToHelpClicked,
  googleSendEWOPaymentHereToHelpClicked,
  googleSendEWOSuccessHereToHelpClicked,
  googleSendEWOProposedImageClicked,
  googleSendEWODangerousCallUsClicked,
  googleSendEWOMajorCallUsClicked,
  googleSendEWODangerousAccepted,
  googleSendEWOMajorAccepted,
  googleSendEWOMinorAccepted,
  googleSendEWOAdvisoryAccepted,
  googleSendEWODangerousDeclined,
  googleSendEWOMajorDeclined,
  googleSendEWOMinorDeclined,
  googleSendEWOAdvisoryDeclined,
  googleSendEWOProposedMenuClicked,
  googleSendEWOProposedCallUsClicked,
  googleSendEWOPaymentCallUsClicked,
  googleSendEWOSuccessCallUsClicked,
  googleSendEWOProposedConfirmClicked,
  googleSendEWOPaymentConfirmClicked,
  googleSendEWOPaymentWarrantyExpanded,
  needHelpClicked,
  needHelpModalCallUsClicked,
  needHelpModalLiveChatClicked,
  needHelpModalEmailUsClicked,
  needHelpModalContactFormClicked,
  needHelpModalFAQClicked,
  editBookingButtonClicked,
  downloadInvoiceButtonClicked,
  downloadServiceSheetButtonClicked,
  viewMotCertificateButtonClicked,
  motPageDisplayed,
  servicesPageDisplayed,
  upsellClickedOnWhatsIncluded,
  fixterGuaranteesClicked,
  productFullDescriptionClicked,
  checkListClicked,
  oilEngineClicked,
  needOtherServicesClicked,
  productTabClicked,
  howItWorksCarouselStepAccessed,
};
