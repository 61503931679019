'use client';

import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/navigation';
import { useModalQueue } from '$util/hooks/useModalQueue';
import { Button, DesignType } from '@/components/atoms/Button';
import { HeadingLevels } from '@/components/atoms/HeadingLevels';
import { dispatchCloseQueuedModalEvent } from '@/components/molecules/Modal';
import styles from './index.module.scss';

interface Link {
  readonly text: string;
  readonly url: string;
}

interface RichTextModalProps {
  readonly modalId: string;
  readonly heading: string;
  readonly subheading?: string;
  readonly content?: ReactNode;
  readonly primaryCTA?: Link;
  readonly secondaryCTA?: Link;
}

export function RichTextModal({
  modalId: id,
  heading,
  subheading,
  content,
  primaryCTA,
  secondaryCTA,
}: RichTextModalProps): ReactNode {
  const modalContentWrapper = useRef<HTMLDivElement>(null);
  const { push } = useRouter();

  const [, queueModal] = useModalQueue();

  const MODAL_TRIGGER_FRAGMENT_PATTERN = /#open-modal=/;

  const openModal = useCallback(() => {
    queueModal([
      // eslint-disable-next-line react/jsx-key
      <div ref={modalContentWrapper}>
        <header>
          {heading && (
            <HeadingLevels semanticLevel={2} styleLevel={2}>
              {heading}
            </HeadingLevels>
          )}
          {subheading && (
            <HeadingLevels semanticLevel={3} styleLevel={6}>
              {heading}
            </HeadingLevels>
          )}
        </header>
        {content}
        {(primaryCTA || secondaryCTA) && (
          <div className={styles.cta}>
            {primaryCTA && <Button onClick={() => push(primaryCTA.url)}>{primaryCTA.text}</Button>}
            {secondaryCTA && (
              <Button onClick={() => push(secondaryCTA.url)} designType={DesignType.SECONDARY_DARK}>
                {secondaryCTA.text}
              </Button>
            )}
          </div>
        )}
      </div>,
      'modal',
    ]);
  }, [content, heading, primaryCTA, push, queueModal, secondaryCTA, subheading]);
  useEffect(() => {
    const shouldOpenModal = () => {
      const openAModal = MODAL_TRIGGER_FRAGMENT_PATTERN.test(window.location.hash);
      if (openAModal) {
        // get the modal's ID
        const modalId = window.location.hash.replace(MODAL_TRIGGER_FRAGMENT_PATTERN, '');

        return modalId === id;
      }

      return false;
    };
    const handleHashChange = () => {
      // has open modal id
      if (shouldOpenModal()) {
        openModal();
      }
      if (window.location.hash === '') {
        if (modalContentWrapper.current) {
          dispatchCloseQueuedModalEvent(modalContentWrapper.current);
        }
      }
    };
    // // open the modal if the page is loaded with a modal hash
    if (shouldOpenModal()) {
      openModal();
    }

    window.addEventListener('hashchange', handleHashChange);

    return () => window.removeEventListener('hashchange', handleHashChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

RichTextModal.displayName = 'RichTextModal';
