/* istanbul ignore file */
import { chunk, omit } from 'lodash';
import mediaHelper from '$util/theme/styledMedia';
import vars, { misc } from './vars';
import { withResponsive } from './withResponsive';
import { getBreakpoint } from './breakpoints';

export { vars, withResponsive };
/**
 * Use from vars, kept for backward compatibility
 * @deprecated
 */
export const {
  defaultBorderRadius,
  gutter, // @deprecated, use `Grid`
  blockElementShadow,
  buttonShadow,
  insetShadow,
  cardShadow,
  cardShadowBig,
  fontWeight,
  mediaQueryBreakpoints,
} = misc;

/**
 * @see https://github.com/dvpnt/styled-media-helper
 */
export const media = mediaHelper(omit(vars.mediaQueryBreakpoints, ['xs360']));

/**
 * Check if window viewport matches one or multiple breakpoints.
 * NOTE: Don't use it for rendering React components, use withResponsive instead.
 * @param  {String, Boolean} args (breakpoint, up = true, ...) repeat parameters to match multiple breakpoints
 */
export const matchesBreakpoint = (...args: any[]): any => {
  if (!process.browser) return false;
  return chunk(args, 2)
    .map(([breakpoint, up = true]) => {
      const width = parseInt(getBreakpoint(breakpoint), 10);
      return `(${up ? 'min' : 'max'}-width: ${up ? width : +width - 1}px)`;
    })
    .reduce((matches, query) => {
      return matches && window.matchMedia(query).matches;
    }, true);
};

export const pxToRem = (px: number, fontSizeBase = vars['font-size-base']): string => {
  return `${px / parseInt(fontSizeBase, 10)}rem`;
};

const theme = {
  ...misc,
  vars,
  getBreakpoint,
  media,
  matchesBreakpoint,
  pxToRem,
  withResponsive,
};

export default theme;

export * from './spacers/horizontals';
