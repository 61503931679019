'use client';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { isTargetingCategoryActive } from '$util/oneTrust';

export interface TrackingPixelProps extends ComponentPropsWithoutRef<'div'> {
  readonly src: string;
}

export function TrackingPixel({ src, className }: TrackingPixelProps): ReactNode {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const consentSetHandler = () => {
      setShouldRender(isTargetingCategoryActive());
    };

    document.body.addEventListener('consent-set', consentSetHandler);

    return () => {
      document.body.removeEventListener('consent-set', consentSetHandler);
    };
  }, []);

  if (!shouldRender) return null;

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={src}
      style={{
        display: 'none',
      }}
      className={className}
      alt=""
      decoding="async"
    />
  );
}

TrackingPixel.displayName = 'TrackingPixel';
