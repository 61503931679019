import React from 'react';
import classNames from 'classnames';
import type Props from './props';
import CardStyled from './styled';

export * from './props';

export function Card({ type = 1, shadow = '', className = '', children = '', ...props }: Props) {
  return (
    <CardStyled className={classNames('Card', `card-${type}`, className)} shadow={shadow} {...props}>
      {children}
    </CardStyled>
  );
}
Card.displayName = 'Card';
