// import 'server-only';

import { Locale } from '@fixter/i18n';
import type { TranslationDictionary } from '@/types/components/translations';

export interface VrmLookupFormTranslations {
  readonly textPostcode: string;
  readonly textPostcodeRequired: string;
  readonly textPostcodeInvalid: string;
  readonly textRegistrationNumber: string;
  readonly textRegistrationNumberRequired: string;
  readonly textRegistrationNumberInvalid: string;
  readonly textCTA: string;
}

export const translations = {
  [Locale.EN_GB]: {
    default: {
      textPostcode: 'Your postcode',
      textPostcodeRequired: 'Enter a correct postcode',
      textPostcodeInvalid: 'Invalid postcode',
      textRegistrationNumber: 'Your registration number',
      textRegistrationNumberRequired: 'Enter a correct VRM',
      textRegistrationNumberInvalid: 'Invalid VRM',
      textCTA: 'Get a quote',
    },
    instantQuote: {
      textCTA: 'Get an instant quote',
    },
    inlineForm: {
      textCTA: 'Get an Instant Quote',
    },
    referral: {
      textCTA: 'Redeem now',
    },
    free: {
      textCTA: 'Book a Free Check',
    },
    errorPage: {
      textCTA: 'Try again',
    },
  },
  [Locale.FR_FR]: {
    default: {
      textPostcode: 'Code postal',
      textPostcodeRequired: 'Entrez un code postal valide',
      textPostcodeInvalid: 'Code postal non valide',
      textRegistrationNumber: `Immatriculation`,
      textRegistrationNumberRequired: `Entrez un numéro d'immatriculation valide`,
      textRegistrationNumberInvalid: `Numéro d'immatriculation non valide`,
      textCTA: 'Obtenir un devis',
    },
    instantQuote: {
      textCTA: 'Obtenir un devis en 1 clic',
    },
    inlineForm: {
      textCTA: 'Obtenir un devis en deux clics',
    },
    referral: {
      textCTA: 'Utiliser maintenant',
    },
    free: {
      textCTA: 'Réserver un contrôle gratuit',
    },
    errorPage: {
      textCTA: 'Réessayer',
    },
    ct: {
      textCTA: 'Prendre RDV',
    },
  },
} satisfies TranslationDictionary<VrmLookupFormTranslations>;
