'use client';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import { get } from 'lodash';
import { Card } from '$components/basic/Card';
import { productImagesSourceList } from '$util/image/productImages';
import { Col } from '@/components/atoms/Grid';
import { Button, DesignType } from '@/components/atoms/Button';
import { Img } from '@/components/atoms/Img';

import styles from './index.module.scss';

export interface WorkOperationProps extends ComponentPropsWithoutRef<'div'> {
  readonly title: string;
  readonly description: string;
  readonly slug: string;
  readonly symptoms?: string;
  readonly categories?: string;
}

const getMappedTitle = (title: string) => {
  if (title === 'Mot and service') return 'MOT and Service';
  return title;
};

export function WorkOperation({
  title,
  description,
  slug,
  symptoms,
  categories,
}: WorkOperationProps): ReactNode {
  const productImageSource = get(productImagesSourceList, slug, '/products/default-optimized_npucqy.png');

  if (['brake-pads', 'brake-discs-pads'].includes(slug)) return null;

  return (
    <Col key={slug} className={styles.cardWrapper} medium={6} extraSmall={12}>
      <Card className={styles.card} type={2}>
        <div className={styles.cardHeader}>
          <div className={styles.cardTitleWrapper}>
            {categories && (
              <div id="workoperation-categories" className={styles.woSection}>
                {categories}
              </div>
            )}
            <div className={styles.woTitle}>{getMappedTitle(title)}</div>
          </div>
          <div className={styles.cardImgWrapper}>
            <Img provider="cloudinary" src={productImageSource} alt={slug} fill />
          </div>
        </div>
        <div className={styles.cardContent}>
          <div className={styles.serviceSection} dangerouslySetInnerHTML={{ __html: description }} />
          {symptoms && (
            <div
              id="workoperation-symptoms"
              className={styles.serviceSection}
              dangerouslySetInnerHTML={{ __html: symptoms }}
            />
          )}
        </div>
        <div className={styles.cardFooter}>
          <Button designType={DesignType.PRIMARY} size="S" href={`/${slug}`}>
            Get a free quote
          </Button>
          <Button designType={DesignType.SECONDARY_DARK} size="S" href={`/${slug}`}>
            Learn more
          </Button>
        </div>
      </Card>
    </Col>
  );
}

WorkOperation.displayName = 'WorkOperation';
