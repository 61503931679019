'use client';

import classNames from 'classnames';
import type { ComponentPropsWithoutRef } from 'react';
import type { CmsLink, CmsShortText } from '$cms/types';
import { useModalQueue } from '$util/hooks/useModalQueue';
import { Link } from '@/components/atoms/Link';
import { Card } from '@/components/atoms/Card';
import { ContentComponentSection } from '@/components/atoms/ContentComponentSection';
import { Col, Row } from '@/components/atoms/Grid';
import { Icon } from '@/components/atoms/Icon';

import styles from './index.module.scss';

type MandatoryItemIndex = '1' | '2' | '3' | '4';

interface ItemRecord extends Record<MandatoryItemIndex, CmsShortText> {
  5?: CmsShortText;
}

interface BrandedIconListCardProps extends ComponentPropsWithoutRef<'div'> {
  readonly itemIcons: ItemRecord;
  readonly itemText: ItemRecord;
  readonly itemSentence: ItemRecord;
  readonly modalTitleText?: CmsShortText;
  readonly link?: CmsLink;
  readonly lazyLoadImages?: boolean;
  readonly preloadImages?: boolean;
  readonly colour?: 'purple' | 'green';
  readonly backgroundColor?: string;
}

type ItemData = {
  readonly icon: string;
  readonly text: string;
  readonly sentence?: string;
};

type MoreDetailsModalProps = {
  items: ItemData[];
  readonly title?: string;
};

function MoreDetailsModal({ items, title }: MoreDetailsModalProps) {
  return (
    <div className={styles.modal}>
      <h4>{title}</h4>
      {items.map((item) => {
        const { icon, text, sentence } = item;

        if (icon && text) {
          return (
            <Card key={icon} className={classNames(styles.modalCard)} cornerRadius={3} elevation={2}>
              <div className={classNames(styles.item, styles.modalItem)}>
                <p className={styles.text}>{text}</p>
                {sentence && <p className={styles.sentenceText}>{sentence}</p>}
                <div className={classNames(styles.icon, styles.modalIcon)}>
                  <Icon legacy={false} id={icon} />
                </div>
              </div>
            </Card>
          );
        }
        return false;
      })}
    </div>
  );
}

export function BrandedIconListCard({
  itemIcons,
  itemText,
  itemSentence,
  modalTitleText,
  link,
  lazyLoadImages,
  preloadImages,
  colour,
  backgroundColor,
  className,
  ...props
}: BrandedIconListCardProps): JSX.Element {
  const [, queueModal] = useModalQueue();

  // get number of items to output
  const numberOfIcons: number = Object.values(itemIcons).filter(Boolean).length;
  const numberOfTextItems: number = Object.values(itemText).filter(Boolean).length;

  const numberToRender = Math.min(numberOfIcons, numberOfTextItems);
  const numberToRenderAsArray = Array.from(
    { length: numberToRender },
    (_, i) => (i + 1).toString() as keyof ItemRecord
  );

  const iconIds = numberToRenderAsArray.flatMap((key) => itemIcons[key]?.value ?? []);
  const textContents = numberToRenderAsArray.flatMap((key) => itemText[key]?.value ?? []);
  const sentenceContents = numberToRenderAsArray.flatMap((key) => itemSentence?.[key]?.value ?? []);

  const itemDataList = Array.from({ length: numberToRender }, (_, i) => `${i + 1}`).map((_, i) => ({
    icon: iconIds[i],
    text: textContents[i],
    sentence: sentenceContents[i],
  }));

  const openMoreDetailsModal = () => {
    queueModal(<MoreDetailsModal items={itemDataList} title={modalTitleText?.value} />).trackModalOpen(
      'usp-hero-24',
      'learn more link'
    );
  };
  return (
    <ContentComponentSection
      className={classNames(
        BrandedIconListCard.displayName,
        styles.component,
        colour && colour === 'green' && styles.green,
        className
      )}
      style={{ backgroundColor }}
      {...props}
    >
      <Row>
        <Col className={styles.contentsColumn}>
          <Card className={styles.card} cornerRadius={3} elevation={2}>
            <ul className={styles.mainContent}>
              {itemDataList.map(({ icon, text }) => (
                <li key={icon} className={styles.item}>
                  <p className={styles.text}>{text}</p>
                  <div className={classNames(styles.icon, styles.adjust)}>
                    <Icon legacy={false} id={icon} />
                  </div>
                </li>
              ))}
            </ul>
            {link?.text && (
              <div className={styles.linkContainer}>
                <Link
                  className={styles.link}
                  direction="none"
                  type="S"
                  href={link.url}
                  onClick={(event) => {
                    event.preventDefault();
                    openMoreDetailsModal();
                  }}
                >
                  {link.text}
                </Link>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </ContentComponentSection>
  );
}

BrandedIconListCard.displayName = 'BrandedIconListCard';
