'use client';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import useSWR from 'swr';
import { SearchSuggestionsType } from '$util/types';
import { changeUrl } from '$util/index';
import { HeadingLevels } from '@/components/atoms/HeadingLevels';
import { TopBackground } from '@/components/atoms/TopBackground';
import { EmailCta } from '@/components/atoms/EmailCta';
import { Img } from '@/components/atoms/Img';
import { Icon } from '@/components/atoms/Icon';
import { ga4Event } from '@/util/ga4Analytics';
import styles from './index.module.scss';

interface FormData {
  readonly search: string;
}

interface SuggestionData {
  readonly name: string;
  readonly url: string;
}

const fetcher = (url: string): Promise<SuggestionData[]> => fetch(url).then((res) => res.json());

export interface HeroBasicProps extends ComponentPropsWithoutRef<'div'> {
  readonly heading: ReactNode;
  readonly subheading?: ReactNode;
  readonly cta?: boolean;
  readonly ctaText?: string;
  readonly mailto?: string;
  readonly background?: boolean;
  readonly isSearch?: boolean;
  readonly searchType?: SearchSuggestionsType;
  readonly searchHeader?: string;
  readonly searchPlaceholder?: string;
  readonly searchError?: ReactNode;
  readonly searchAnalytics?: boolean;
}

export function HeroBasic({
  heading,
  subheading,
  cta = false,
  ctaText,
  mailto,
  background = true,
  isSearch,
  searchType,
  searchHeader,
  searchPlaceholder,
  searchError,
  searchAnalytics = false,
}: HeroBasicProps): ReactNode {
  const searchRef = useRef<HTMLInputElement>(null);
  const [formState, setFormState] = useState<FormData>({ search: '' });
  const [isFocussed, setIsFocussed] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const { data } = useSWR<SuggestionData[]>(
    `/api/search-suggestions?data=${encodeURIComponent(JSON.stringify({ type: searchType ?? SearchSuggestionsType.PRODUCT }))}`,
    fetcher
  );
  const [suggestions, isLoadingSuggestions] = useMemo(() => [data || [], !data], [data]);
  const filteredSuggestions = useMemo(
    () => suggestions.filter((item) => item.name.toLowerCase().includes(formState.search.toLowerCase())),
    [suggestions, formState.search]
  );

  const onSuggestionSelected = (item: SuggestionData) => {
    setIsSelected(true);
    if (searchAnalytics) {
      ga4Event('suggestion-selected', { trigger: item.name });
    }
    setFormState({ search: item.name });
    setIsFocussed(false);
    changeUrl({ pathname: item.url });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormState({ search: value });
  };

  const handleFocus = () => {
    if (searchAnalytics && !isSelected) {
      ga4Event('focus-in', { trigger: formState.search });
    }
    setIsFocussed(true);
  };
  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      if (searchAnalytics && !isSelected) {
        ga4Event('focus-out', { trigger: formState.search });
      }
      setIsFocussed(false);
    }
  };

  const highlightText = (text: string, highlight: string) => {
    if (!highlight.trim()) {
      return text;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    return text.split(regex).map((part, index) =>
      // eslint-disable-next-line react/no-array-index-key
      part.toLowerCase() === highlight.toLowerCase() ? <b key={index}>{part}</b> : part
    );
  };

  const handleClear = () => {
    if (searchAnalytics) {
      ga4Event('clear', { trigger: formState.search });
    }
    setFormState({ search: '' });
  };

  const renderContent = (containerClassName?: string) => {
    return (
      <div className={classNames(styles.container, containerClassName)} onBlur={handleBlur} tabIndex={-1}>
        {heading && (
          <HeadingLevels className={classNames('HeadingLevels', styles.heading)} semanticLevel={2}>
            {heading}
          </HeadingLevels>
        )}
        {subheading && (
          <HeadingLevels className={classNames('HeadingLevels', styles.subheading)} semanticLevel={3}>
            {subheading}
          </HeadingLevels>
        )}
        {cta && ctaText && mailto && <EmailCta cta={ctaText} mailto={mailto} />}
        {isSearch && (
          <div className={styles.searchCard}>
            <HeadingLevels className={classNames('HeadingLevels', styles.searchHeader)} semanticLevel={4}>
              {searchHeader}
            </HeadingLevels>
            <div
              className={classNames(styles.searchContainer, { [styles.focussed]: isFocussed })}
              onBlur={handleBlur}
              onClick={() => searchRef.current && searchRef.current.focus()}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  if (searchRef.current) {
                    searchRef.current.focus();
                  }
                }
              }}
            >
              <div className={styles.inputIcon}>
                <Icon
                  id="general/search-sm"
                  legacy={false}
                  color={isFocussed ? styles.colorNeutral600 : styles.colorNeutral300}
                />
              </div>
              <input
                id="search-input"
                ref={searchRef}
                type="text"
                name="search"
                value={formState.search}
                onChange={handleChange}
                className={classNames(styles.input)}
                placeholder={searchPlaceholder ?? ''}
                onFocus={handleFocus}
                aria-label="Search input"
              />
              {formState.search && (
                <div
                  role="button"
                  tabIndex={0}
                  aria-label="Clear search input"
                  onClick={handleClear}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleClear();
                    }
                  }}
                  className={styles.cancelIcon}
                >
                  <Icon id="general/x" legacy={false} color={styles.colorNeutral600} />
                </div>
              )}
              {isFocussed && (
                <div id="search-suggestions" className={styles.suggestions}>
                  {isLoadingSuggestions && (
                    <div className={styles.loading}>
                      <Img
                        provider="cloudinary"
                        src="/icons/icon_spinner_ua5yua.svg"
                        alt="spinner"
                        height={50}
                        width={50}
                      />
                    </div>
                  )}
                  {!isLoadingSuggestions &&
                    filteredSuggestions.length > 0 &&
                    filteredSuggestions.map((item) => (
                      <div key={item.name} className={styles.suggestionItemContainer}>
                        <div
                          id="suggestion-item"
                          role="button"
                          aria-label={item.name}
                          onMouseDown={() => onSuggestionSelected(item)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              onSuggestionSelected(item);
                            }
                          }}
                          className={styles.suggestionItem}
                          tabIndex={0}
                        >
                          <p>{highlightText(item.name, formState.search)}</p>
                        </div>
                      </div>
                    ))}
                  {!isLoadingSuggestions && !filteredSuggestions.length && (
                    <div id="search-error" className={styles.searchError}>
                      {searchError}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  if (!background) {
    return renderContent();
  }

  return <TopBackground>{renderContent(styles.containerLarge)}</TopBackground>;
}

HeroBasic.displayName = 'HeroBasic';
